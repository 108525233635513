import { ClipboardModule } from '@angular/cdk/clipboard'
import { Component, Inject } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { imports } from '../../../imports'

@Component({
  selector: 'app-modal-order',
  templateUrl: './modal-order.html',
  styleUrls: ['./modal-order.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatCheckboxModule,
    ClipboardModule,
  ],
})
export class ModalOrderComponent {
  get linkUrl() {
    return location.origin + '/order-payment/' + this.data.item.link_code + '?openExternalBrowser=1'
  }

  get textCopy() {
    return `เลขที่ใบสั่งซื้อ: ${this.data.item.order_no}
รายละเอียดการโอนอยู่ในลิงก์ ลูกค้าสามารถติดตามออเดอร์
และชำระเงินได้ทางลิงก์นี้
${this.linkUrl}`
  }

  constructor(
    public dialogRef: MatDialogRef<ModalOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar
  ) {}

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }
}
