import { Component, OnInit } from '@angular/core'
import { InputComponent } from '../../../../../components/input/input'
import { ModalUserComponent } from './modal-user/modal-user'
import { ModalDownloadFileComponent } from '../../../../../components/modal-download-file/modal-download-file'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { StatusUserComponent } from '../../../../../components/status-user/status-user'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { SearchFilterComponent } from './search-filter/search-filter'
import { MatTooltipModule } from '@angular/material/tooltip'
import { CheckboxTableComponent } from '../../../../../components/checkbox-table/checkbox-table.component'
import { CheckboxAllTableComponent } from '../../../../../components/checkbox-table/checkbox-all-table.component'
import { UserService } from '../../../../../services'
import {
  ACTION_ACCESS,
  APP_PERMISSION,
  FilterTableUser,
  Loading,
  Profile,
} from '../../../../../globals'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { DownloadFileBlob, Moment, Pagination } from '../../../../../helpers'
import { imports } from '../../../../../imports'
import { TextTooltipComponent } from '../../../../../components/text-tooltip/text-tooltip'
@Component({
  selector: 'app-tab-user-manage',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    MatPaginatorModule,
    ModalUserComponent,
    ModalDownloadFileComponent,
    StatusUserComponent,
    MatCheckboxModule,
    SearchFilterComponent,
    MatTooltipModule,
    CheckboxTableComponent,
    CheckboxAllTableComponent,
    TextTooltipComponent,
  ],
  templateUrl: './tab-user-manage.html',
  styleUrls: ['./tab-user-manage.scss'],
})
export class TabUserManageComponent implements OnInit {
  readonly Moment = Moment
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  itemChecked = new Set<any>()
  readonly paginationUser = new Pagination({
    sortName: 'code',
  })
  userList: any[] = []

  constructor(
    public profile: Profile,
    public dialog: MatDialog,
    public userService: UserService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableUser: FilterTableUser
  ) {}

  ngOnInit(): void {
    this.getUserList()
  }

  getUserList() {
    const pagination = this.paginationUser.get()
    const dataFilter = this.filterTableUser.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }
    // console.log(payload)
    // return

    this.loading.start()
    this.userService.getUserList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.userList = res.data.records
          this.paginationUser.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalUser(title: string, type: 'add' | 'edit', dataManage: any): void {
    const dialogRef = this.dialog.open(ModalUserComponent, {
      disableClose: true,
      data: {
        title,
        type,
        dataManage,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUserList()
      }
    })
  }

  openModalDownloadFile(title: string, detail: any): void {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })
  }

  downloadFile() {
    const pagination = this.paginationUser.get()
    const dataFilter = this.filterTableUser.getData()
    dataFilter.filter.ids = this.itemChecked.size > 0 ? [...this.itemChecked.values()] : undefined

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }

    this.loading.start()
    this.userService.exportUser(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `users.xlsx`)
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
