import { Component, Inject } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { forkJoin } from 'rxjs'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import { InputComponent } from '../../../../components/input/input'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { SelectSearchAsyncComponent } from '../../../../components/select-search-async/select-search-async'
import { SelectComponent } from '../../../../components/select/select'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import { Loading } from '../../../../globals'
import {
  Ascending,
  Moment,
  ValidateForm,
  convertDateToApi,
  setErrorForm,
} from '../../../../helpers'
import { imports } from '../../../../imports'
import { ProductService, PromotionService, TierService } from '../../../../services'

@Component({
  selector: 'app-modal-promotion',
  templateUrl: './modal-promotion.html',
  styleUrls: ['./modal-promotion.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatCheckboxModule,
    DatePickerComponent,
    InputComponent,
    InputNumberComponent,
    SelectComponent,
    TextareaComponent,
    DatePickerComponent,
    MatSlideToggleModule,
    SelectSearchAsyncComponent,
  ],
})
export class ModalPromotionComponent {
  is_limit = new FormControl(true)
  is_percent = new FormControl(null, [Validators.required])
  effective_date_from = new FormControl('', [Validators.required])
  effective_date_to = new FormControl('', [
    (control: AbstractControl) => {
      const start = this.effective_date_from.value
      if (start && control.value) {
        if (Moment(start).unix() > Moment(control.value).unix()) {
          return {
            minMax: true,
          }
        }
      }

      return null
    },
  ])
  form = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    is_percent: this.is_percent,
    value: new FormControl('', [
      Validators.required,
      (control: AbstractControl) => {
        const value = control.value || 0
        if (this.is_percent.value === true && value > 100) {
          return {
            max: true,
          }
        }

        return null
      },
    ]),
    tiers: new FormControl<any[]>([]),
    products: new FormControl<any[]>([], [Validators.required]),
    min_amount: new FormControl(''),
    description: new FormControl(''),
    is_limit: this.is_limit,
    total_code: new FormControl('', [
      (control: AbstractControl) => {
        if (this.is_limit.value && !control.value) {
          return {
            required: true,
          }
        }

        return null
      },
    ]),
    code_per_person: new FormControl('', [
      (control: AbstractControl) => {
        if (this.is_limit.value && !control.value) {
          return {
            required: true,
          }
        }

        return null
      },
    ]),
    effective_date_from: this.effective_date_from,
    effective_date_to: this.effective_date_to,
    is_active: new FormControl(true),
  })

  dataManage: any

  list = {
    persent: <any[]>[
      { value: false, text: 'บาท' },
      { value: true, text: 'เปอร์เซ็นต์' },
    ],
    tier: <any[]>[],
    product_detail: <any[]>[{ key: 'all', full_name: 'ทั้งหมด' }],
  }

  // readonly product_option_all = { key: 'all', full_name: 'ทั้งหมด' }

  get unitValue() {
    return this.list.persent.find((d: any) => d.value === this.form.controls['is_percent'].value)
      ?.text
  }

  get disableValue() {
    return ![true, false].includes(this.form.controls['is_percent'].value as any)
  }

  constructor(
    public dialogRef: MatDialogRef<ModalPromotionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public promotionService: PromotionService,
    public tierService: TierService,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    const apis = [this.tierService.getTierList({})]

    if (this.data.type == 'edit') {
      apis.push(this.promotionService.getPromotion(this.data.dataManage.id))
    } else {
      this.form.reset({
        is_limit: true,
        is_active: true,
      })
    }

    if (apis.length) {
      this.loading.start()
      forkJoin(apis).subscribe(([resTier, resPromotion]: any) => {
        if (resTier) {
          if (!resTier.is_error) {
            this.list.tier = [
              { id: 'all', name: 'ทั้งหมด' },
              ...Ascending(
                resTier.data.filter((d: any) => d.is_active || resPromotion?.tier_id),
                'name'
              ),
            ]
          } else {
            this.customSnackBar.fail(resTier.message)
          }
        }

        if (resPromotion) {
          if (!resPromotion.is_error) {
            this.dataManage = resPromotion.data

            const tiers = this.dataManage.tiers || []
            const products = (this.dataManage.products || []).map((d: any) => {
              const productDetail = {
                ...d,
                full_name: d.product.name + (d.product_detail ? ' - ' + d.product_detail.name : ''),
                key: this.setProductKey({
                  product_id: d.product_id,
                  product_brand_id: d.product_brand_id,
                  product_detail_id: d.product_detail_id,
                }),
              }

              if (!this.list.product_detail.some((pd: any) => pd.key == productDetail.key)) {
                this.list.product_detail.push(productDetail)
              }

              return productDetail
            })

            this.dataManage.products = products

            this.form.reset({
              code: this.dataManage.code,
              name: this.dataManage.name,
              description: this.dataManage.description,
              is_percent: this.dataManage.is_percent,
              value: this.dataManage.value,
              tiers: this.dataManage.is_all_tier ? ['all'] : tiers.map((t: any) => t.tier_id),
              products: this.dataManage.is_all_product
                ? [{ key: 'all', full_name: 'ทั้งหมด' }]
                : products,
              min_amount: this.dataManage.min_amount,
              is_limit: this.dataManage.is_limit,
              total_code: this.dataManage.total_code,
              code_per_person: this.dataManage.code_per_person,
              effective_date_from: this.dataManage.effective_date_from,
              effective_date_to: this.dataManage.effective_date_to,
              is_active: this.dataManage.is_active,
            })

            for (const tier of tiers) {
              if (!this.list.tier.some((d: any) => d.id == tier.tier_id)) {
                this.list.tier.push(tier.tier)
              }
            }

            for (const product of products) {
              if (!this.list.product_detail.some((d: any) => d.id == product.key)) {
                const data: any = {
                  key: product.key,
                  product_id: product.product_id,
                  product_brand_id: product.product_brand_id,
                  product_detail_id: product.product_detail_id,
                  full_name: product.product_detail
                    ? product.product.name + ' - ' + product.product_detail.name
                    : product.product.name,
                }
                this.list.product_detail.push(data)
              }
            }
          } else {
            this.customSnackBar.fail(resPromotion.message)
          }
        }

        this.loading.stop()
      })
    }
  }

  loadingProduct = 0
  #getProductCount = 0
  getProducts(search: string) {
    this.loadingProduct++
    this.#getProductCount++
    const getProductCount = this.#getProductCount
    this.productService
      .getProductList({
        sort_name: 'name',
        sort_by: 'asc',
        page: 1,
        page_size: 20,
        search: search ? search : undefined,
        filter: {
          is_actives: [true],
        },
      })
      .subscribe(res => {
        if (getProductCount == this.#getProductCount) {
          if (!res.is_error) {
            this.list.product_detail = [
              { key: 'all', full_name: 'ทั้งหมด' },
              ...Ascending(
                res.data.records.reduce((ds: any[], d: any) => {
                  if (d.details.length) {
                    for (const detail of d.details) {
                      const data: any = {
                        product_id: detail.product_id,
                        product_brand_id: d.product_brand_id,
                        product_detail_id: detail.id,
                        full_name: d.name + ' - ' + detail.name,
                      }
                      data.key = this.setProductKey(data)
                      ds.push(data)
                    }
                  } else {
                    const data: any = {
                      product_id: d.id,
                      product_brand_id: d.product_brand_id,
                      product_detail_id: null,
                      full_name: d.name,
                    }
                    data.key = this.setProductKey(data)
                    ds.push(data)
                  }
                  return ds
                }, []),
                'full_name'
              ),
            ]
          } else {
            this.customSnackBar.fail(res.message)
          }
        }
        setTimeout(() => {
          this.loadingProduct--
        }, 500)
      })
  }

  setProductKey(item: { product_id: any; product_brand_id: any; product_detail_id?: any }) {
    return item.product_id + ',' + item.product_brand_id + ',' + (item.product_detail_id || '')
  }

  changeTier(e: any) {
    if (e.source.value == 'all') {
      if (e.source.selected) {
        if (!(this.form.controls.tiers.value || []).some((v: any) => v == 'all')) {
          this.form.controls.tiers.setValue(['all'])
        }
      } else {
        this.form.controls.tiers.setValue([])
      }
    }
  }

  changeProduct(e: any) {
    if (e.source.value.key == 'all' && e.isUserInput) {
      if (e.source.selected) {
        if (!(this.form.controls.products.value || []).some((v: any) => v.key == 'all')) {
          this.form.controls.products.setValue([{ key: 'all', full_name: 'ทั้งหมด' }])
        }
      } else {
        this.form.controls.products.setValue([])
      }
    }
  }

  isAllOption(value: any) {
    return value && value.some((v: any) => v == 'all')
  }

  isAllOptionProduct(value: any) {
    return value && value.some((v: any) => v.key == 'all')
  }

  onClose() {
    if (this.data.type == 'edit' && this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    if (this.data.type == 'edit') {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave(): void {
    const isEdit = this.data.type == 'edit'

    const value = this.form.getRawValue()
    const is_all_tier = this.isAllOption(this.form.controls['tiers'].value)
    const is_all_product = this.isAllOptionProduct(this.form.controls['products'].value)
    const payload = {
      code: value.code,
      name: value.name,
      description: value.description,
      is_percent: value.is_percent,
      value: value.value,
      is_all_tier,
      is_all_product,
      tiers: is_all_tier
        ? []
        : (value.tiers || []).map((tier_id: any) => {
            const oldTier =
              this.dataManage &&
              (this.dataManage.tiers || []).find((d: any) => d.tier_id == tier_id)
            return {
              id: oldTier ? oldTier.id : null,
              tier_id,
            }
          }),
      products: is_all_product
        ? []
        : value.products?.map((product: any) => {
            const oldProduct =
              this.dataManage &&
              (this.dataManage.products || []).find((d: any) => d.key == product.key)
            return {
              id: oldProduct ? oldProduct.id : null,
              product_id: product.product_id ? parseInt(product.product_id) : null,
              product_brand_id: product.product_brand_id
                ? parseInt(product.product_brand_id)
                : null,
              product_detail_id: product.product_detail_id
                ? parseInt(product.product_detail_id)
                : null,
            }
          }),
      min_amount: value.min_amount || null,
      is_limit: value.is_limit,
      total_code: value.total_code,
      code_per_person: value.code_per_person,
      effective_date_from: convertDateToApi(value.effective_date_from),
      effective_date_to: value.effective_date_to
        ? convertDateToApi(value.effective_date_to, 'day')
        : null,
      is_active: value.is_active,
    }

    // return console.log(payload)

    const api = isEdit
      ? this.promotionService.updatePromotion(this.data.dataManage.id, payload)
      : this.promotionService.addPromotion(payload)
    this.loading.start()
    api.subscribe((res: any) => {
      if (!res.is_error) {
        this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
        this.dialogRef.close(true)
      } else {
        if (res.errors[0]?.field) {
          setErrorForm(this.form, res.errors)
        } else {
          this.customSnackBar.failSave(res.message)
        }
      }
      this.loading.stop()
    })
  }
}
