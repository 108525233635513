<div
  #box
  class="box-text-tooltip {{ styleClass }}"
  matTooltipPosition="above"
  matTooltipClass="tooltip-black tooltip-customer-tier"
  [matTooltip]="text"
  [matTooltipShowDelay]="500">
  <span #content class="line-camp-{{ lineCamp }}">
    <ng-content></ng-content>
  </span>
</div>
