<div class="page-content-inner mt-4">
  <div class="title-blue">รายการสั่งซื้อ</div>

  <div class="mt-2">
    <app-search-filter-customer-order
      placeholder="ค้นหา"
      [customer]="dataManage"
      (onConfirm)="paginationOrder.reset();getCustomerOrderList()"></app-search-filter-customer-order>
  </div>

  <div class="table-responsive-detail mt-3">
    <table class="table table-fixed-column table-detail">
      <thead>
        <tr>
          <th width="160">เลขที่รายการ</th>
          <th width="160">วันที่โอนเงิน</th>
          <th width="250">ธนาคาร</th>
          <th width="160">วันที่ปิดรายการ</th>
          <th width="160">เลขพัสดุ</th>
          <th width="160" class="text-end">ยอดขาย</th>
          <th width="160" class="text-end">กะรัตที่ได้</th>
          <th width="160">สถานะ</th>
        </tr>
      </thead>
      <tbody>
        @for (item of orderList; track $index) {
        <tr>
          <td>
            <a
              [href]="'/order/edit/' + item.order_id"
              target="_blank"
              class="color--brand-blue text-decoration-none">
              {{ item.order_no }}
            </a>
          </td>
          <td>
            {{ item.payment_date ? Moment(item.payment_date).format('DD-MM-YYYY , HH:mm') : '-' }}
          </td>
          <td>
            @for (itemB of item.banks; track $index) {
            <div class="d-flex align-items-center">
              <div class="box-bank-img">
                <img src="/assets/images/logos/banks/{{ itemB.code }}.png" width="20" />
              </div>
              {{ itemB.name }}
            </div>
            } @empty {-}
          </td>
          <td>
            {{ item.closed_date ? Moment(item.payment_date).format('DD-MM-YYYY , HH:mm') : '-' }}
          </td>
          <td>
            @if (item.trackingText) {
            <span [innerHTML]="item.trackingText"></span>
            } @else { - }
          </td>
          <td class="text-end color--brand-blue--f">{{ formatPrice(item.total_price) }}</td>
          <td class="text-end color--green-600--f">{{ formatPriceAutoDecimal(item.carat) }}</td>
          <td>{{ item.status.name }}</td>
        </tr>
        }
      </tbody>
    </table>

    @if(!orderList.length) {
    <div class="data-not-found-wrapper">ไม่พบรายการสั่งซื้อ</div>
    }
  </div>

  <!-- Pagination -->
  @if (orderList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationOrder.data.pageIndex"
      [length]="paginationOrder.data.length"
      [pageSize]="paginationOrder.data.pageSize"
      [pageSizeOptions]="paginationOrder.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationOrder.setFromPaginator($event);getCustomerOrderList()">
    </mat-paginator>
  </div>
  }
</div>
