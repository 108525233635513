import { Component } from '@angular/core'
import { imports } from '../../../imports'
import { MatDialog } from '@angular/material/dialog'
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { SelectComponent } from '../../../components/select/select'
import { SearchComponent } from '../../../components/search/search'
import { Loading, Profile } from '../../../globals'
import { Router, ActivatedRoute } from '@angular/router'
import { CaratService, MasterService, PromotionService, PublicService } from '../../../services'
import { OrderService } from '../../../services/order.service'
import { MatMenuModule } from '@angular/material/menu'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { forkJoin } from 'rxjs'
import {
  Moment,
  ValidateForm,
  checkUseCarat,
  formatPrice,
  formatPriceAutoDecimal,
  imgProductDefault,
  productImage,
  setErrorForm,
} from '../../../helpers'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import { checkPomotionExpired } from '../../../helpers/promotion'
// import { ShippingService } from '../../../services/shipping.service'
// import { SHIPPING_TYPE } from '../../../helpers/shipping'

@Component({
  selector: 'app-order-return',
  standalone: true,
  imports: [
    ...imports,
    SearchComponent,
    SelectComponent,
    MatMenuModule,
    InputNumberComponent,
    UploadImageProfileComponent,
    TextareaComponent,
  ],
  templateUrl: './order-return.html',
  styleUrls: ['./order-return.scss'],
})
export class OrderReturnComponent {
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  products = new FormArray<FormGroup>([])

  form = new FormGroup({
    remark: new FormControl('', [Validators.required]),
    file_id: new FormControl(null),
  })

  order_id = null
  order: any
  payment: any
  #promotion: any
  newDiscountCodePrice = 0

  list = {
    customer: <any[]>[],
    channel: <any[]>[],
    transport: <any[]>[],
  }

  shipping = {
    standard_cost: 0,
    condition_type: 1,
    min_order: 0,
    min_no_order: 0,
    shipping_cost: 0,
  }
  carat = {
    total_order_amount: 0,
  }

  get promotion() {
    return checkPomotionExpired(this.#promotion)
  }

  get totalProduct() {
    return this.products.controls.reduce(
      (total, form: FormGroup) => {
        const product = form.controls['data'].value
        const quantity = form.controls['quantity'].value || 0
        const totalPriceReturn = form.controls['data'].value.price * quantity
        const totalPrice = product.total_price - totalPriceReturn
        const count = form.controls['data'].value.quantity - quantity
        total.totalCountReturn += quantity
        total.totalPriceReturn += totalPriceReturn
        total.totalCount += count
        total.totalPrice += totalPrice
        if (product.is_carat) {
          total.totalPriceOnlyCarat += totalPrice
        }

        let totalPriceRemain = totalPrice
        if (this.promotion) {
          if (
            this.promotion.is_all_product ||
            (this.promotion.products || []).some(
              (p: any) =>
                p.product_id == product.product_id &&
                p.product_detail_id == product.product_detail_id
            )
          ) {
            if (this.promotion.is_percent) {
              total.discountCodePrice += (this.promotion.value * totalPriceRemain) / 100
            } else {
              total.discountCodePrice = this.promotion.value
            }
          }
        }

        total.totalPriceRemain += totalPriceRemain

        return total
      },
      {
        totalCountReturn: 0,
        totalPriceReturn: 0, //ราคาสินค้าที่คืน
        totalCount: 0,
        totalPrice: 0, //ไม่รวมส่วนลด
        totalPriceOnlyCarat: 0, //ไม่รวมส่วนลดเฉพาะสินค้าที่เข้าร่วมกะรัต
        totalPriceRemain: 0, //รวมส่วนลด
        discountCodePrice: 0, //ส่วนลด
      }
    )
  }

  get shippingCost() {
    // if (this.shipping.condition_type != SHIPPING_TYPE.STANDARD) {
    //   const totalProductPrice = this.totalProduct.totalPrice
    //   const totalProductCount = this.totalProduct.totalCount

    //   const isMinTotal = totalProductPrice >= this.shipping.min_order
    //   const isMinCount = totalProductCount >= this.shipping.min_no_order

    //   if (this.shipping.condition_type == SHIPPING_TYPE.MIN_TOTAL && isMinTotal) {
    //     return this.shipping.shipping_cost
    //   } else if (this.shipping.condition_type == SHIPPING_TYPE.COUNT_PRODUCT && isMinCount) {
    //     return this.shipping.shipping_cost
    //   } else if (
    //     this.shipping.condition_type == SHIPPING_TYPE.MIN_TOTAL_AND_COUNT_PRODUCT &&
    //     (isMinTotal || isMinCount)
    //   ) {
    //     return this.shipping.shipping_cost
    //   }
    // }
    return this.shipping.standard_cost
    // return this.order.shipping_cost || 0
  }

  get discount() {
    return this.order.discount || 0
  }

  get discountCodePrice() {
    return this.order.discount_code_price || 0
  }

  get creditCodePrice() {
    return this.order.credit_code_price || 0
  }

  get caratCodePrice() {
    return this.order.carat_code_price || 0
  }

  get sumTotal() {
    let total =
      this.payment.previous_payment +
      this.discount +
      this.discountCodePrice +
      this.creditCodePrice +
      this.caratCodePrice -
      this.totalProduct.totalPriceReturn

    //use promotion
    if (total > 0) {
      total -= this.newDiscountCodePrice
      if (total < 0) {
        total = 0
      }
    }

    return total
  }

  get caratAmount() {
    const sumTotalOnlyIsCarat = this.totalProduct.totalPriceOnlyCarat

    const totalCrat =
      this.carat.total_order_amount && sumTotalOnlyIsCarat
        ? Math.floor(sumTotalOnlyIsCarat / this.carat.total_order_amount)
        : 0
    return totalCrat > 0 ? totalCrat : 0
  }

  get previousPayment() {
    return this.payment?.previous_payment || 0
  }

  get calcPayment() {
    const previousPayment = this.previousPayment
    const sumTotal = this.sumTotal
    return previousPayment - sumTotal
  }

  get additionPayment() {
    //ยอดที่ต้องชำระเพิ่ม
    return this.calcPayment
  }

  get returnCredit() {
    //ยอดที่ชำระเกิน
    return this.calcPayment > 0 ? this.calcPayment : 0
  }

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public masterService: MasterService,
    public orderService: OrderService,
    public caratService: CaratService,
    public publicService: PublicService,
    public promotionService: PromotionService
  ) // public shippingService: ShippingService
  {}

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.initData(params['id'])
      this.order_id = params['id']
    })
  }

  initData(id: any) {
    const apis = [
      this.caratService.getCarat(1),
      this.orderService.getOrder(id),
      // this.shippingService.getShipping(1),
    ]

    this.loading.start()
    forkJoin(apis).subscribe(([resCarat, resOrder, resShipping]: any) => {
      if (!resCarat.is_error) {
        if (checkUseCarat(resCarat.data)) {
          this.carat = resCarat.data
        }
      } else {
        this.customSnackBar.fail(resCarat.message)
      }

      // if (resShipping) {
      //   if (!resShipping.is_error) {
      //     this.shipping.condition_type = resShipping.data.condition_type || SHIPPING_TYPE.STANDARD
      //     this.shipping.min_no_order = resShipping.data.min_no_order || 0
      //     this.shipping.min_order = resShipping.data.min_order || 0
      //     this.shipping.shipping_cost = resShipping.data.shipping_cost || 0
      //     this.shipping.standard_cost = resShipping.data.standard_cost || 0
      //   } else {
      //     this.customSnackBar.fail(resCarat.message)
      //   }
      // }

      if (!resOrder.is_error) {
        this.publicService.getPaymentByOrderId(resOrder.data.id).subscribe((resPayment: any) => {
          if (!resPayment.is_error) {
            this.payment = resPayment.data
            this.order = resOrder.data

            for (const product of this.order.products) {
              if (product.quantity > 0) {
                const form = new FormGroup(
                  {
                    data: new FormControl(product),
                    name: new FormControl(product.product_name),
                    img: new FormControl(productImage(product)?.file?.url || imgProductDefault),
                    quantity: new FormControl(null),
                  },
                  {}
                )
                this.products.push(form)
              }
            }

            if (this.payment.promotion_id) {
              this.promotionService
                .getPromotion(this.payment.promotion_id)
                .subscribe(resPomotion => {
                  if (!resPomotion.is_error) {
                    this.#promotion = resPomotion.data
                    this.useDiscountCode(true)
                  }
                })
            }
          } else {
            this.customSnackBar.fail(resPayment.message)
          }
        })
      } else {
        this.customSnackBar.fail(resOrder.message)
      }

      this.loading.stop()
    })
  }

  setQuantity(num: 1 | -1, item: FormGroup) {
    const quantity = item.controls['quantity'].value
    const max = item.controls['data'].value.quantity || 0
    if (num == 1 ? quantity < max : quantity > 0) {
      item.controls['quantity'].setValue(quantity + num)
    }
    this.useDiscountCode()
  }

  toList() {
    this.router.navigate(['/order'])
  }

  payloadProducts() {
    const products = this.products.getRawValue()
    return products
      .map((d: any) => {
        const quantity = d.data.quantity - d.quantity
        const price = d.data.price

        return {
          id: null,
          product_id: d.data.product_id || null,
          product_detail_id: d.data.product_detail_id || null,
          delivery_address_id: null,
          quantity,
          price,
          total_price: quantity * price,
          tracking_no: '',
          cost: d.data.cost,
          delivery_date: null,
          is_return_product: false,
          return_quantity: 0,
          actual_quantity: 0,
          is_return_credit: false,
          bank_id: d.data.bank_id || null,
          return_bank_account_name: '',
          return_bank_account_no: '',
          return_total_price: 0,
          return_shipping_cost: 0,
          return_discount_code_price: 0,
          return_credit_code_price: 0,
          return_carat_code_price: 0,
          delivery_address_uuid: d.data.delivery_address_uuid,
        }
      })
      .filter(d => d.quantity > 0)
  }

  ci = 0
  async useDiscountCode(isLoading = false) {
    this.ci++
    const ci = this.ci
    return new Promise<number>(rev => {
      const promotion = this.promotion
      if (promotion) {
        const products = this.payloadProducts()
        if (products.length) {
          if (isLoading) {
            this.loading.start()
          }

          const payload = {
            total_price: this.totalProduct.totalPrice,
            customer_id: this.order.customer_id,
            products: this.payloadProducts(),
          }

          this.publicService.checkPromotion(promotion.id, payload).subscribe((res: any) => {
            if (this.ci == ci && !res.is_error) {
              this.newDiscountCodePrice = res.data || 0
              rev(this.newDiscountCodePrice)
            } else {
              this.newDiscountCodePrice = 0
              rev(0)
            }
            if (isLoading) {
              this.loading.stop()
            }
          })
        } else {
          this.newDiscountCodePrice = 0
          rev(0)
        }
      } else {
        rev(0)
      }
    })
  }

  async onConfirm() {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const details = this.products.controls.reduce((details: any[], form: FormGroup) => {
      const quantity = form.controls['quantity'].value
      if (quantity) {
        // รายการคืนสินค้า
        details.push({
          order_product_id: form.controls['data'].value.id, // ไอดีของ order.procusts.id
          quantity, // จำนวนที่จะคืน
        })
      }
      return details
    }, <any[]>[])

    if (!details.length) {
      this.customSnackBar.fail('ไม่พบจำนวนที่ต้องการคืน')
      return
    }

    this.useDiscountCode(true)
    const discount_code_price = this.newDiscountCodePrice
    const value = this.form.getRawValue()
    const payload = {
      remark: value.remark,
      file_id: value.file_id, // ไฟล์แนบ
      total_price: this.totalProduct.totalPriceRemain, // ราคารวมสินค้าคงเหลือ
      discount_code_price, // ส่วนลดจากโปรโมชั่น
      promotion_id: this.promotion?.id || null, // ถ้าโปรโมชั่นเดิมที่เคยใช้ยังใช้ได้ ก็ส่งไอดีโปรโมชั่น
      remain_total: this.sumTotal, // ยอดรวมสุทธิ หลังหักส่วนลด ถ้ามี
      previous_payment: this.previousPayment, // เอาจาก get payment by order ฟิว `previous_payment`
      addition_payment:
        this.additionPayment < 0 ? Math.abs(this.additionPayment) : -this.additionPayment, // ยอดที่ต้องชำระเพิ่ม
      carat: this.caratAmount, // คำนวณกะรัตที่จะได้ใหม่
      return_credit: this.returnCredit, // ยอดคืนเครดิต ถ้ายอดเกินที่จ่ายไว้ก็คืนเป็นเครดิต
      return_discount_code_price: this.discountCodePrice,
      return_credit_code_price: this.creditCodePrice,
      return_carat_code_price: this.caratCodePrice,
      details,
    }

    // return console.log(payload)

    this.loading.start()
    this.orderService.returnOrder(this.order_id, payload).subscribe((res: any) => {
      if (!res.is_error) {
        this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
        this.toList()
      } else {
        if (res.errors[0]?.field) {
          setErrorForm(this.form, res.errors)
        } else {
          this.customSnackBar.failSave(res.message)
        }
      }
      this.loading.stop()
    })
  }
}
