<div class="order-log-wrapper">
  @for (item of orderList; track $index) {
  <div class="order-log-item">
    <div class="order-log-time-wrapper">
      <div class="order-log-number">{{ item.order_no }}</div>
      <div class="order-log-datetime">
        {{item.today ? 'วันนี้' : Moment(item.created_at).format('DD-MM-YYYY') }}
      </div>
      <div class="order-log-datetime">{{ Moment(item.created_at).format('HH:mm') }}</div>
      <div class="order-log-post">{{ item.transport.name }}</div>
    </div>

    <!-- checkbox, orange, green, grey -->

    @if (orderSelect.has(item.id)) {
    <div
      class="order-log-action-wrapper checkbox {{ isShipment(item.status_id) ? 'grey' : isPrinted(item.status_id) ? 'green' : 'orange' }}"
      (click)="orderSelect.delete(item.id)">
      <div class="order-log-action">
        <span class="icon-check icon-24 material-symbols-outlined fill">done_outline</span>
      </div>
    </div>
    } @else {
    <div
      class="order-log-action-wrapper {{ isShipment(item.status_id) ? 'grey' : isPrinted(item.status_id) ? 'green' : 'orange' }}"
      (click)="orderSelect.set(item.id, item)">
      <div class="order-log-action"></div>
    </div>
    }

    <div class="order-log-detail-wrapper">
      <div class="order-log-status-wrapper">
        <div class="order-log-status-item" [class.active]="item.isCreate">
          <div class="order-log-status-text">สร้างออเดอร์</div>
          <div class="order-log-status-bar"></div>
        </div>
        <div class="order-log-status-item" [class.active]="item.isPaid">
          <div class="order-log-status-text">ชำระเงินแล้ว</div>
          <div class="order-log-status-bar"></div>
        </div>
        <div class="order-log-status-item" [class.active]="item.isPrinted">
          <div class="order-log-status-text">พิมพ์ที่อยู่ / ใบเสร็จแล้ว</div>
          <div class="order-log-status-bar"></div>
        </div>
        <div class="order-log-status-item" [class.active]="item.isSipment">
          <div class="order-log-status-text">ส่งสินค้าแล้ว</div>
          <div class="order-log-status-bar"></div>
        </div>
      </div>

      <div class="order-log-detail-box">
        <div class="order-log-detail-body d-flex gap-3">
          @if (item.status_id == ORDER_ID.VERIFY && item.payment && item.payment.slip_url &&
          item.is_verify) {
          <div class="box-img-payment d-flex justify-content-center">
            <div>
              <img [src]="item.payment.slip_url" />
            </div>
          </div>
          }
          <div class="order-info-wrapper flex-1">
            <div class="d-flex justify-content-between align-items-start">
              <div class="order-info-inner-wrapper">
                <div class="customer-info">
                  <div class="info-text-lg info-text-bold color--brand-blue">
                    {{ item.customer_name }}
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    @if (channelIcon[item.channel_id]) {
                    <img
                      width="20"
                      height="21"
                      src="/assets/images/icons/icon-social-{{ channelIcon[item.channel_id] }}.svg"
                      [alt]="item.channel.name || '-'" />
                    }
                    <div class="info-text-md">
                      {{ item.channel?.name || 'ไม่ระบุ' }}@if (item.channel_name) {:
                      <!-- ~ -->
                      @if (item.line_url) {
                      <a
                        [href]="item.line_url"
                        target="_blank"
                        class="color--brand-Blue name-channel"
                        [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.channel_name)"></a>
                      } @else {
                      <app-text-tooltip>
                        <span class="name-channel" [innerHTML]="item.channel_name"></span>
                      </app-text-tooltip>
                      }
                      <!-- ! -->
                      }
                    </div>
                  </div>
                </div>
                <div class="customer-info">
                  <div class="net-total">ยอดรวมสุทธิ: {{ formatPrice(item.total) }}</div>
                  <div class="create-by">สร้างโดย: {{ item.created_by.full_name }}</div>
                </div>
                @if (item.is_payment && isShowOverdue(item)) {
                <div class="payment-overdue">
                  @if (item.payment_amount > item.total) {
                  <!-- ~ -->
                  ชำระเกิน: {{ formatPrice(item.payment_amount - item.total) }}
                  <!-- ~ -->
                  } @else if (item.payment_amount < item.total) {
                  <!-- ~ -->
                  ค้างชำระ: {{ formatPrice(item.total - item.payment_amount) }}
                  <!-- ~ -->
                  } @else if (item.payment_amount == 0) {
                  <!-- ~ -->
                  ค้างชำระ: {{ formatPrice(item.total) }}
                  <!-- ~ -->
                  }
                </div>
                }
              </div>

              @if (isPrinted(item.status_id)) {
              <button matRipple [matMenuTriggerFor]="print" class="btn btn-success">
                พิมพ์แล้ว
                <span class="icon material-symbols-rounded fill">arrow_drop_down</span>
              </button>
              } @else {
              <button
                matRipple
                [matMenuTriggerFor]="print"
                [disabled]="!item.isActionPrint"
                class="btn btn-orange">
                พิมพ์
                <span class="icon material-symbols-rounded fill">arrow_drop_down</span>
              </button>
              }
              <mat-menu #print="matMenu" class="print-menu-wrapper">
                <div
                  matRipple
                  class="mat-menu-item d-flex align-items-center"
                  (click)="printSngle.emit({
                    view: 'address', id: item.id, isCopy: false
                  })">
                  <span>ที่อยู่</span>
                </div>
                <div
                  matRipple
                  class="mat-menu-item d-flex align-items-center"
                  (click)="printSngle.emit({
                    view: 'receipt', id: item.id, isCopy: false
                  })">
                  <span>ใบเสร็จรับเงิน</span>
                </div>
                @if (item.is_tax) {
                <div
                  matRipple
                  class="mat-menu-item d-flex align-items-center"
                  (click)="printSngle.emit({
                    view: 'tax', id: item.id, isCopy: false
                  })">
                  <span>ใบกำกับภาษี (ต้นฉบับ)</span>
                </div>
                <div
                  matRipple
                  class="mat-menu-item d-flex align-items-center"
                  (click)="printSngle.emit({
                    view: 'tax', id: item.id, isCopy: true
                  })">
                  <span>ใบกำกับภาษี (สำเนา)</span>
                </div>
                }
              </mat-menu>
            </div>

            <div class="d-flex justify-content-between align-items-start">
              <div class="order-info-inner-wrapper pe-1">
                @for (delivery_address of item.delivery_addresses_used; track $index) { @if (!$index
                || item.showAllAddress) {
                <div class="customer-info" [class.mt-2]="$index > 0">
                  <div class="d-flex align-items-center gap-2">
                    <span class="icon icon-dark-grey material-symbols-rounded fill"
                      >local_shipping</span
                    >
                    <div class="info-text-md info-text-bold">{{ delivery_address.name }}</div>
                  </div>
                  <div class="separator">|</div>
                  <div class="info-text-md">{{ delivery_address.telephone }}</div>
                </div>
                <div class="info-text-md text-pre-line" [class.mb-2]="$index > 0">
                  <app-text-tooltip> {{ delivery_address.address || '-' }} </app-text-tooltip>
                </div>
                }} @if (item.delivery_addresses_used.length > 1 && item.is_multi_delivery) {
                @if(!item.showAllAddress) {
                <div (click)="item.showAllAddress = true" class="view-address">
                  ดูที่อยู่จัดส่งทั้งหมด
                  <span class="icon material-symbols-rounded fill">expand_more</span>
                </div>
                } @else {
                <div (click)="item.showAllAddress = false" class="view-address">
                  ซ่อนที่อยู่จัดส่งทั้งหมด
                  <span class="icon material-symbols-rounded fill">expand_less</span>
                </div>
                }} @if (item.remark) {
                <div class="order-remark mt-2">หมายเหตุ: {{ item.remark }}</div>
                }
              </div>
              <div class="d-flex gap-2 align-items-center">
                <span class="icon-16 icon-dark material-symbols-outlined fill">call</span>
                <div class="font-14 color--neutral-950">{{ item.telephone || '-' }}</div>
              </div>
            </div>
            @if (isShowPayment(item) && item.is_verify) {
            <!-- ~ -->
            @if (item.payment) {
            <!-- ~ -->
            @for (er of item.payment.errors; track $index) {
            <div class="box-log-error d-flex align-items-center gap-1">
              <img src="/assets/images/icons/icon-payyment-log-error.svg" alt="ic" />
              {{ er }}
            </div>
            }
            <!-- ~ -->
            @for (tf of item.payment.transfers; track $index) {
            <!-- ~ -->
            @if (tf.bank_id) {
            <div class="transfer-bank d-flex align-items-center gap-1">
              {{ tf.message }}
              <!-- ~ -->
              @if (tf.bank.code != BANK_NONE.CODE) {
              <img
                src="/assets/images/logos/banks/{{ tf.bank.code }}.png"
                width="20"
                height="20"
                alt="Bank" />
              }
              <!-- ~ -->
              {{ tf.bank.name }} วันที่ {{ Moment(tf.payment_date).format('DD-MM-YYYY, HH:mm') }}
            </div>
            }
            <!-- ~ -->
            }
            <!-- ~ -->
            }
            <!-- ~ -->
            }
            <!-- ~ -->
            @if (item.is_payment) {
            <div>
              <button
                matRipple
                class="btn btn-outline-secondary"
                (click)="openModalSlip('verify', item)">
                หลักฐานการโอนเงิน
              </button>
            </div>
            }
          </div>
        </div>
        <div class="order-log-detail-footer">
          <div class="order-footer-button">
            @if (item.action.closed) {
            <!-- % -->
            @if (profile.permissions.checkAccess([APP_PERMISSION.ORDER], [ACTION_ACCESS.UPDATE])) {
            <button
              matRipple
              (click)="openModalClose('ปิดรายการ', item)"
              class="btn {{isShipment(item.status_id) ? 'btn-secondary' : 'btn-outline-secondary'}}">
              <span class="icon material-symbols-outlined fill">check_box</span>
              {{isShipment(item.status_id) ? 'ปิดรายการแล้ว' : 'ปิดรายการ'}}
            </button>
            }
            <!-- % -->
            }
            <!-- ~ -->
            @if (item.status_id == ORDER_ID.CREATE) {
            <button
              matRipple
              (click)="openModalSlip('create', item)"
              class="btn btn-outline-secondary">
              <span class="icon material-symbols-outlined fill">payments</span>
              ชำระเงิน
            </button>
            }
            <!-- ~ -->
            @if (item.status_id == ORDER_ID.VERIFY) {
            <!-- % -->
            @if (profile.permissions.checkAccess([APP_PERMISSION.ORDER], [ACTION_ACCESS.UPDATE]) &&
            item.is_verify) {
            <button matRipple (click)="confirmAccept(item)" class="btn btn-success">
              <img src="/assets/images/icons/icon-check-white.svg" alt="ic" />
              ยอมรับ
            </button>
            <button matRipple (click)="confirmReject(item)" class="btn btn-danger">
              <img src="/assets/images/icons/icon-close-white.svg" alt="ic" />
              ปฏิเสธ
            </button>
            }
            <!-- ~ -->
            }
            <!-- ~ -->
            @if (isPayment(item.status_id)) {
            <button matRipple (click)="openModalSlip('paid', item)" class="btn btn-secondary">
              <span class="icon material-symbols-outlined fill">payments</span>
              ชำระเงินแล้ว
            </button>
            }

            <button
              matRipple
              (click)="openModalOrder('คำสั่งซื้อ', item)"
              class="btn btn-outline-secondary">
              <span class="icon material-symbols-outlined fill">list_alt</span>
              คำสั่งซื้อ
            </button>

            <button
              matRipple
              (click)="openModalViewProduct('รายการสินค้า', item)"
              class="btn btn-outline-secondary">
              <span class="icon material-symbols-outlined fill">shopping_basket</span>
              รายการสินค้า
            </button>

            @if (item.action.cancel) {
            <button
              matRipple
              (click)="openModalCancelClose(item)"
              class="btn btn-outline-secondary">
              <span class="icon material-symbols-outlined fill">cancel</span>
              ยกเลิกปิดรายการ
            </button>
            }
          </div>
          <div class="order-footer-button">
            @if (item.action.edit) {
            <button
              matRipple
              [routerLink]="['/order/edit/' + item.id]"
              class="btn btn-outline-secondary">
              <span class="icon material-symbols-outlined fill">edit</span>
              แก้ไข
            </button>
            }
            <button matRipple (click)="openModalDelete(item)" class="btn btn-outline-danger">
              <span class="icon material-symbols-outlined fill">delete</span>
              ลบ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</div>
