import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private service: Service) {}
  private pathOrder = '/v1/orders'

  getOrderList(payload: any): Observable<any> {
    return this.service.post(`${this.pathOrder}/query`, payload)
  }
  getOrder(id: number): Observable<any> {
    return this.service.get(`${this.pathOrder}/${id}`)
  }
  addOrder(payload: any): Observable<any> {
    return this.service.post(`${this.pathOrder}`, payload)
  }
  updateOrder(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathOrder}/${id}`, payload)
  }
  deleteOrder(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathOrder}/${id}`, payload)
  }
  deleteOrderWithRemark(id: number, payload: any = {}): Observable<any> {
    return this.service.put(`${this.pathOrder}/${id}/delete`, payload)
  }
  printedOrder(id: any, payload: any = {}): Observable<any> {
    return this.service.put(`${this.pathOrder}/${id}/printed`, payload)
  }
  closeOrder(id: number, payload: any = {}): Observable<any> {
    return this.service.put(`${this.pathOrder}/${id}/closed`, payload)
  }
  cancelClosedOrder(id: number, payload: any = {}): Observable<any> {
    return this.service.put(`${this.pathOrder}/${id}/cancel-closed`, payload)
  }
  mergeOrder(payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathOrder}/merge`, payload)
  }
  returnOrder(id: any, payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathOrder}/${id}/return`, payload)
  }
  count(payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathOrder}/query-count`, payload)
  }
  checkImportOrder(payload: any): Observable<any> {
    return this.service.post(`${this.pathOrder}/pre-import`, payload)
  }
  importOrder(payload: any): Observable<any> {
    return this.service.post(`${this.pathOrder}/import`, payload)
  }
  exportOrder(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathOrder}/export`, payload)
  }
  templateOrder(payload: any): Observable<any> {
    return this.service.getBlob(`${this.pathOrder}/template`, payload)
  }
  uploads(id: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathOrder}/${id}/uploads`, payload)
  }
  deleteFilePayment(fileId: any, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathOrder}/payment-files/${fileId}`, payload)
  }
  accept(id: any, payload: any = {}): Observable<any> {
    return this.service.put(`${this.pathOrder}/${id}/accept`, payload)
  }
  reject(id: any, payload: any = {}): Observable<any> {
    return this.service.put(`${this.pathOrder}/${id}/reject`, payload)
  }
  printTax(id: any, payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathOrder}/${id}/print-tax`, payload)
  }
  getLogs(id: any, payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathOrder}/${id}/logs`, payload)
  }
}
