<div class="search-filter-wrapper">
  <app-input-search
    [control]="filterReportProduct.search"
    placeholder="{{placeholder || ''}}"
    (onEnter)="filterReportProduct.confirm(); onConfirm.emit()"></app-input-search>

  <button (click)="openModalFilter()" matRipple class="btn btn-outline-dark">
    <span class="icon material-symbols-outlined fill">filter_list</span>ตัวกรอง
  </button>
</div>

@if (filterReportProduct.isFilter) {
<div class="filter-wrapper mb-3">
  <div class="filter-title-wrapper">
    <div class="filter-title">ตัวกรอง</div>
    <div class="filter-separator">|</div>
    <button
      matRipple
      class="btn btn-transparent btn-clear"
      (click)="filterReportProduct.reset(); afterFilter()">
      ล้างค่า
    </button>
  </div>

  <div class="filter-tag-wrapper">
    @if (filterReportProduct.isGetName(filterReportProduct.form.value.category, 'category')) {
    <div class="filter-tag-item">
      <div class="filter-tag">
        {{ filterReportProduct.isGetName(filterReportProduct.form.value.category, 'category').name
        }}
      </div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterReportProduct.resetCategory(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
    <!-- ~ -->
    @if (filterReportProduct.isGetName(filterReportProduct.form.value.brand, 'brand')) {
    <div class="filter-tag-item">
      <div class="filter-tag">
        {{ filterReportProduct.isGetName(filterReportProduct.form.value.brand, 'brand').name }}
      </div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterReportProduct.resetBrand(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
    <!-- ~ -->
    @if (filterReportProduct.isGetName(filterReportProduct.form.value.period, 'period')) {
    <div class="filter-tag-item">
      <div class="filter-tag">
        {{ filterReportProduct.isGetName(filterReportProduct.form.value.period, 'period').name }}
      </div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterReportProduct.resetPeriod(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
    <!-- ~ -->
    @if (filterReportProduct.startEndDateFormat) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterReportProduct.startEndDateFormat }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterReportProduct.removeStartEndDate(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
    <!-- ~ -->
    @if (filterReportProduct.form.value.status_closed) {
    <div class="filter-tag-item">
      <div class="filter-tag">เฉพาะรายการที่ปิดแล้ว</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterReportProduct.form.controls.status_closed.reset(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
  </div>
</div>
}
