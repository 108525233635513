<div class="tabs-content-wrapper">
  <div class="top-content-wrapper">
    <div class="top-content-left mb-3">
      <app-search
        [control]="filterTableHistoryOrderDeleted.search"
        placeholder="ค้นหา"
        (onEnter)="paginationHistory.reset();getList();itemChecked.clear()"></app-search>
    </div>
    <div class="top-content-right mb-3">
      <label class="label"> วันที่ลบออเดอร์ </label>
      <app-date-picker-range
        [controlStr]="form.controls['start_deleted_date']"
        [controlEnd]="form.controls['end_deleted_date']"
        [hideError]="true"
        (onChange)="paginationHistory.reset();getList();itemChecked.clear()"></app-date-picker-range>

      @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        class="btn btn-outline-secondary"
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายการที่ลบ', { message: 'คุณต้องการดาวน์โหลดไฟล์รายการที่ลบที่เลือกใช่หรือไม่' })">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive table-main-tabs-wrapper">
    <table class="table table-fixed-column">
      <thead>
        <tr class="tr-vertical-top">
          @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
          <th width="70" class="colum-checkbox">
            <app-checkbox-all-table
              [checkedValue]="itemChecked"
              [datas]="historyList"
              fieldValue="id"></app-checkbox-all-table>
          </th>
          }
          <th width="160">
            <app-sort-by key="order_no" [pagination]="paginationHistory" (onChange)="getList()">
              เลขที่ออเดอร์
            </app-sort-by>
          </th>
          <th width="200">
            <app-sort-by
              key="customer_name"
              [pagination]="paginationHistory"
              (onChange)="getList()">
              ชื่อลูกค้า
            </app-sort-by>
          </th>
          <th width="160">เบอร์โทรศัพท์</th>
          <th width="250">ที่อยู่จัดส่ง</th>
          <th width="160">ช่องทางโซเชียล</th>
          <th width="160">ชื่อโซเชียล</th>
          <th width="180">รหัสสินค้า</th>
          <th width="250">ชื่อสินค้า</th>
          <th width="100" class="text-center">จำนวน</th>
          <th width="160" class="text-end">ราคาต่อหน่วย (บาท)</th>
          <th width="160" class="text-end">รวม (บาท)</th>
          <th width="250">สินค้าจัดส่งหลายที่อยู่</th>
          <th width="160">การจัดส่ง</th>
          <th width="200">หมายเหตุ</th>
          <th width="160" class="text-end">ราคารวมสินค้า (บาท)</th>
          <th width="160" class="text-end">ค่าจัดส่ง</th>
          <th width="160" class="text-end">ส่วนลดเพิ่มเติม (บาท)</th>
          <th width="160" class="text-end">รหัสส่วนลด (บาท)</th>
          <th width="160" class="text-end">ส่วนลดเครดิต (บาท)</th>
          <th width="160" class="text-end">ส่วนลดกะรัต (บาท)</th>
          <th width="160" class="text-end">ยอดรวมสุทธิ (บาท)</th>
          <th width="160">กะรัตที่ได้</th>
          <th width="160">สร้างออเดอร์โดย</th>
          <th width="160">
            <app-sort-by key="created_at" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่สร้างออเดอร์
            </app-sort-by>
          </th>
          <th width="160">ลบออเดอร์โดย</th>
          <th width="160">
            <app-sort-by key="deleted_at" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่ลบออเดอร์
            </app-sort-by>
          </th>
        </tr>
      </thead>

      <tbody>
        @for (item of historyList; track item) {
        <tr class="tr-vertical-top">
          @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
          <td class="colum-checkbox">
            <app-checkbox-table [checkedValue]="itemChecked" [value]="item.id"></app-checkbox-table>
          </td>
          }
          <td>{{ item.order_no || '-' }}</td>
          <td>
            <app-text-tooltip>{{ item.customer_name || '-' }}</app-text-tooltip>
          </td>
          <td>{{ item.telephone || '-' }}</td>
          <td>{{ item.is_multi_delivery ? '-' : item.main_address }}</td>
          <td>{{ item.channel?.name || '-' }}</td>
          <td>
            <app-text-tooltip>
              <span class="name-channel" [innerHTML]="item.channel_name || '-'"></span>
            </app-text-tooltip>
          </td>
          <td class="px-0 py-3" colspan="6">
            <table class="w-100">
              <colgroup>
                <col width="180" />
                <col width="250" />
                <col width="100" />
                <col width="160" />
                <col width="160" />
                <col width="250" />
              </colgroup>

              <tbody>
                @for (product of item.products; track $index) {
                <tr class="tr-sub-product">
                  <td>{{ product.item.code || '-' }}</td>
                  <td>
                    <app-text-tooltip>{{ product.item.name || '-' }}</app-text-tooltip>
                  </td>
                  <td class="text-center">{{ formatPriceAutoDecimal(product.quantity) }}</td>
                  <td class="text-end">{{ formatPrice(product.price) }}</td>
                  <td class="text-end">{{ formatPrice(product.total_price) }}</td>
                  <td>
                    <app-text-tooltip
                      >{{ product.delivery_address?.address || '-' }}</app-text-tooltip
                    >
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </td>
          <td>{{ item.transport_name || '-' }}</td>
          <td>
            <app-text-tooltip>{{ item.remark || '-' }}</app-text-tooltip>
          </td>
          <td class="text-end">{{ formatPrice(item.total_product_price) }}</td>
          <td class="text-end">{{ formatPrice(item.shipping_cost) }}</td>
          <td class="text-end">{{ formatPrice(item.discount) }}</td>
          <td class="text-end">{{ formatPrice(item.discount_code_price) }}</td>
          <td class="text-end">{{ formatPrice(item.credit_code_price) }}</td>
          <td class="text-end">{{ formatPrice(item.carat_code_price) }}</td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          <td>{{ formatPriceAutoDecimal(item.received_carat) }}</td>
          <td>{{ item.created_by?.full_name || '-' }}</td>
          <td>{{ item.created_at ? Moment(item.created_at).format('DD-MM-YYYY HH:mm') : '-' }}</td>
          <td>{{ item.deleted_by?.full_name || '-' }}</td>
          <td>{{ item.deleted_at ? Moment(item.deleted_at).format('DD-MM-YYYY HH:mm') : '-' }}</td>
        </tr>
        }
      </tbody>
      @if (historyList.length) {
      <tfoot class="tfoot-fixed">
        <tr class="tr-sum-total">
          <td
            [attr.colspan]="profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW]) ? 2 : 1">
            รวม
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-end">{{ formatPrice(sumField('total_product_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('shipping_cost')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('discount')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('discount_code_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('credit_code_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('carat_code_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('total_price')) }}</td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
        </tr>
      </tfoot>
      }
    </table>

    @if(!historyList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูลรายการที่ลบ</div>
    }
  </div>

  <!-- Pagination -->
  @if (historyList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationHistory.data.pageIndex"
      [length]="paginationHistory.data.length"
      [pageSize]="paginationHistory.data.pageSize"
      [pageSizeOptions]="paginationHistory.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationHistory.setFromPaginator($event);getList()">
    </mat-paginator>
  </div>
  }
</div>
