<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toList()">
      ออเดอร์
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">{{ isCreate ? 'สร้างออเดอร์' : 'แก้ไขออเดอร์' }}</div>
  </div>

  @if(isView) {
  <div class="title-wrapper">ข้อมูลสินค้า</div>
  } @else {
  <div class="title-wrapper">{{ isCreate ? 'สร้างออเดอร์' : 'แก้ไขออเดอร์' }}</div>
  }

  <div class="page-content-inner mb-3">
    <span class="title-blue">ข้อมูลผู้ส่ง</span>

    <div class="d-flex flex-wrap mt-3">
      <b>{{ companyProfile?.sender_name || '-' }}</b>
      <span class="mx-2">|</span>
      <span> {{ companyProfile?.sender_telephone || '-' }} </span>
    </div>
    <span> {{ companyProfile?.sender_address || '-' }} </span>

    <div class="line mt-4"></div>

    <div class="row">
      <div class="col-12 col-sm-6 col-lg-8">
        <div class="col-12 col-lg-12 mb-3">
          <span class="title-blue">ข้อมูลลูกค้า</span>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <label
              class="form-label"
              [class.text-danger]="form.controls['customer_name'].touched && form.controls['customer_name'].invalid">
              ชื่อลูกค้า
            </label>
            <app-input-customer
              [control]="form.controls['customer_name']"
              [datas]="list.customer"
              [tiers]="list.tier"
              [disabled]="!isCreate"
              (onSelectionChange)="customerSelection($event)"
              (onChangeList)="list.customer = $event">
              @if (form.controls['customer_name'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['customer_name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['customer_name'].hasError('formApi')) {
              <ng-container error>
                {{ form.controls['customer_name'].errors?.['formApi']}}
              </ng-container>
              }
            </app-input-customer>
          </div>
          <div class="col-12 col-lg-6">
            <label
              class="form-label"
              [class.text-danger]="form.controls['telephone'].touched && form.controls['telephone'].invalid">
              เบอร์โทรศัพท์
            </label>
            <app-input [control]="form.controls['telephone']" [disabled]="!isCreate">
              @if (form.controls['telephone'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['telephone'].hasError('pattern')) {
              <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
              } @else if (form.controls['telephone'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['telephone'].hasError('formApi')) {
              <ng-container error>{{ form.controls['telephone'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
          </div>

          <div class="col-12 col-lg-6">
            <label class="form-label"> ที่อยู่จัดส่ง </label>

            @for (item of delivery_addresses; track $index) {
            <div class="line-address mb-3 font-14">
              <div class="d-flex flex-wrap justify-content-between align-items-center">
                <div class="d-flex flex-wrap">
                  <b> {{ item.name }} </b>
                  <span class="mx-2">|</span>
                  <span> {{ item.telephone }} </span>
                </div>
                <button
                  matRipple
                  class="btn btn-transparent blue"
                  (click)="openModalAddress('แก้ไขที่อยู่', 'edit', item)"
                  [disabled]="!isEditField">
                  <span class="icon-16 material-symbols-outlined fill"> edit </span>
                  แก้ไข
                </button>
              </div>
              <div class="mb-{{item.is_main ? 2 : 3}} text-pre-line">{{ item.address }}</div>
              @if (item.is_main) {
              <div class="mb-3 address-default">ที่อยู่หลัก</div>
              }
            </div>
            }

            <button
              matRipple
              class="btn btn-outline-secondary w-100 mb-2"
              (click)="openModalAddress('เพิ่มที่อยู่', 'add', {})"
              [disabled]="!isEditField">
              <span class="icon-16 icon-secondary material-symbols-outlined fill">
                add_circle
              </span>
              เพิ่มที่อยู่ใหม่
            </button>
          </div>
          <div class="col-12 col-lg-6"></div>

          <div class="col-12 col-lg-6">
            <label
              class="form-label"
              [class.text-danger]="form.controls['channel_id'].touched && form.controls['channel_id'].invalid">
              ช่องทางโซเชียล
            </label>

            <app-select
              [control]="form.controls['channel_id']"
              [datas]="channelList"
              fieldValue="id"
              placeholder="เลือก"
              [disabled]="!isCreate"
              (selectionChange)="selectChannel()">
              <ng-template #optionRef let-option>
                <div class="d-flex align-items-center gap-2">
                  @if (channelIcon[option.id]) {
                  <img
                    [alt]="option.name"
                    src="/assets/images/icons/icon-social-{{ channelIcon[option.id] }}.svg"
                    width="20" />
                  } {{ option.name }}
                </div></ng-template
              >
              @if (form.controls['channel_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              }@else if (form.controls['channel_id'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['channel_id'].errors?.['formApi']}}</ng-container
              >
              }
            </app-select>
          </div>

          <div class="col-12 col-lg-6">
            <label class="form-label"> ชื่อโซเชียล </label>
            @if (!channel_id.value || channel_id.value == channelId.none || !isCreate) {
            <div
              class="div-input-disabled name-channel"
              [innerHTML]="form.controls['channel_name'].value"></div>
            } @else {
            <app-input
              [control]="form.controls['channel_name']"
              [disabled]="!channel_id.value || channel_id.value == channelId.none || !isCreate">
              @if (form.controls['channel_name'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['channel_name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['channel_name'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['channel_name'].errors?.['formApi']}}</ng-container
              >
              }
            </app-input>
            }
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <!-- @if (isCreate || [ORDER_ID.CREATE, ORDER_ID.VERIFY].includes(dataManage?.status_id) ||
        dataManage?.is_tax) { -->
        <div class="col-12 col-lg-12 mb-3">
          <span class="title-blue">ใบกำกับภาษี</span>
        </div>
        <div class="mb-3" [formGroup]="form">
          <mat-checkbox formControlName="is_tax" (change)="onCheckTax()">
            ออกใบกำกับภาษี
          </mat-checkbox>
        </div>
        <!-- } -->
        <!-- ~ -->
        @if (form.controls['is_tax'].value) {
        <div>
          <label
            class="form-label"
            [class.text-danger]="formTax.controls['tax_name'].touched && formTax.controls['tax_name'].invalid">
            ชื่อ-นามสกุล / บริษัทผู้เสียภาษี <span class="text-danger">*</span>
          </label>

          <app-input [control]="formTax.controls['tax_name']">
            @if (formTax.controls['tax_name'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (formTax.controls['tax_name'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            }@else if (formTax.controls['tax_name'].hasError('formApi')) {
            <ng-container error>{{ formTax.controls['tax_name'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div>
          <label
            class="form-label"
            [class.text-danger]="formTax.controls['tax_id'].touched && formTax.controls['tax_id'].invalid">
            เลขประจำตัวผู้เสียภาษี <span class="text-danger">*</span>
          </label>

          <app-input [control]="formTax.controls['tax_id']">
            @if (formTax.controls['tax_id'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (formTax.controls['tax_id'].hasError('pattern')) {
            <ng-container error>Tax ID ไม่ถูกต้อง</ng-container>
            }@else if (formTax.controls['tax_id'].hasError('formApi')) {
            <ng-container error>{{ formTax.controls['tax_id'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div>
          <div class="row">
            <div
              class="form-label d-flex align-items-center col-12 mb-0"
              [class.text-danger]="formTax.controls['tax_address'].touched && formTax.controls['tax_address'].invalid">
              ที่อยู่ในการออกใบกำกับภาษี <span class="text-danger ms-1"> *</span>
            </div>
            <!-- @if (form.controls.customer_id.value) {
            <div class="col-12 col-lg-6 d-flex justify-content-sm-end" [formGroup]="formTax">
              <mat-checkbox
                formControlName="is_tax_same_address"
                (change)="setTaxAddress()"
                (change)="checkTaxSameAddress()">
                ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน
              </mat-checkbox>
            </div>
            } -->
          </div>

          <app-text-area
            [control]="formTax.controls['tax_address']"
            remainingText="250"
            maxlength="250">
            @if (formTax.controls['tax_address'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (formTax.controls['tax_address'].hasError('formApi')) {
            <ng-container error
              >{{ formTax.controls['tax_address'].errors?.['formApi']}}</ng-container
            >
            }
          </app-text-area>
        </div>

        <div>
          <label
            class="form-label"
            [class.text-danger]="formTax.controls['tax_telephone'].touched && formTax.controls['tax_telephone'].invalid">
            เบอร์โทรศัพท์ <span class="text-danger">*</span>
          </label>

          <app-input [control]="formTax.controls['tax_telephone']">
            @if (formTax.controls['tax_telephone'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (formTax.controls['tax_telephone'].hasError('pattern')) {
            <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
            } @else if (formTax.controls['tax_telephone'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            } @else if (formTax.controls['tax_telephone'].hasError('formApi')) {
            <ng-container error
              >{{ formTax.controls['tax_telephone'].errors?.['formApi']}}</ng-container
            >
            }
          </app-input>
        </div>
        }
      </div>
    </div>

    <div class="line mt-2"></div>
    <div class="mb-3" [formGroup]="form">
      <mat-checkbox
        formControlName="is_multi_delivery"
        (change)="resetProductAddress();form.markAsUntouched();">
        สินค้าจัดส่งหลายที่อยู่
      </mat-checkbox>
    </div>
    <div class="d-flex justify-content-between">
      <span class="title-blue">
        ข้อมูลสินค้า
        <span class="text-danger">*</span>
      </span>

      <div class="d-flex">
        @if (!isCreate) {
        <button
          matRipple
          class="btn btn-outline-secondary min-w-120px my-2 me-2"
          [routerLink]="['/order/return/'+ order_id]"
          [disabled]="!dataManage?.action?.return">
          คืนสินค้า
        </button>
        }

        <!-- @if (products.length) {
        <button
          matRipple
          class="btn btn-outline-secondary min-w-120px my-2"
          (click)="openModalProduct('แก้ไขสินค้า', 'edit')"
          [disabled]="dataManage?.is_confirm_address">
          <span class="icon-16 material-symbols-outlined fill"> edit </span>
          แก้ไขสินค้า
        </button>
        } -->
      </div>
    </div>
    <div class="table-responsive-detail mt-2">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            <th width="390">สินค้า</th>
            <th width="120" class="text-end">จำนวน</th>
            <th width="232" class="text-end">ราคา (บาท)</th>
            <th width="232" class="text-end">รวม (บาท)</th>
            @if (isEditField) {
            <th width="200" class="text-end"></th>
            }
          </tr>
        </thead>

        <tbody>
          @for (item of products; track item.index) {
          <tr>
            <td>
              <div class="d-flex align-items-center">
                <img
                  class="img-table me-3"
                  src="{{ productImage(item)?.file?.url || imgDefault }}" />
                <div class="d-flex flex-column w-75">
                  <div class="mb-2">{{ item.product_name }}</div>
                  @if (form.controls.is_multi_delivery.value) {
                  <app-select
                    [control]="item.delivery_address_uuid"
                    [datas]="delivery_addresses"
                    fieldValue="uuid"
                    placeholder="เลือกที่อยู่จัดส่ง"
                    #deliveryAddress
                    (selectionChange)="item.delivery_address_id = deliveryAddress.dataSelect[0].id;form.markAsDirty()">
                    <ng-template #optionRef let-option>
                      <div class="d-flex color--neutral-950">
                        {{ option.name }} <span class="mx-1">|</span> {{ option.telephone}}
                      </div>
                      <div class="color--neutral-500 font-14">{{ option.address }}</div>
                    </ng-template>
                    @if (item.delivery_address_uuid.hasError('required')) {
                    <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                    }
                  </app-select>
                  }
                </div>
              </div>
            </td>
            <td class="text-end">
              @if (!isEditField) {
              <!-- ~ -->
              {{ formatPriceAutoDecimal(item.quantity.value) }}
              <!-- ~ -->
              } @else {
              <!-- max: {{ maxQuantity(item) }} -->
              <div [style]="{ 'max-height': '40px' }">
                @if (!stopRender) {
                <div class="input-number-fake">
                  <app-input-number
                    [control]="item.quantity"
                    [min]="1"
                    [max]="maxQuantity(item)"
                    [decimal]="0"
                    placeholder="0"
                    [remove_bottom]="true"
                    (onChange)="setValueProductStock(products)"
                    (onInput)="resetPayment()">
                  </app-input-number>
                  <div class="btn-num-right">
                    <button matRipple (click)="setQuantity(1, item)">
                      <img src="/assets/images/icons/icon-btn-number-up.svg" />
                    </button>
                    <button matRipple (click)="setQuantity(-1, item)">
                      <img src="/assets/images/icons/icon-btn-number-down.svg" />
                    </button>
                  </div>
                </div>
                }
              </div>
              }
            </td>
            <td class="text-end">
              @if (!isEditField) {
              <!-- ~ -->
              {{ formatPrice(item.price.value) }}
              <!-- ~ -->
              } @else {
              <div class="d-flex justify-content-end">
                <div [style]="{ 'max-width': '140px', 'max-height': '40px' }">
                  <app-input-number
                    [control]="item.price"
                    [remove_bottom]="true"
                    [min]="0"
                    (onInput)="resetPayment()">
                    @if (item.price.hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    }
                  </app-input-number>
                </div>
              </div>
              }
            </td>
            <td class="text-end">{{ formatPrice(sumPrice(item)) }}</td>
            @if (isEditField) {
            <td class="text-center">
              <div class="d-flex justify-content-center">
                <button
                  matRipple
                  class="btn btn-outline-danger button-del"
                  (click)="removeProduct(item, $index)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
            </td>
            }
          </tr>
          }
        </tbody>
      </table>

      @if (!products.length) {
      <div
        class="data-not-found-wrapper d-flex flex-column justify-content-center align-items-center">
        <div>ไม่พบสินค้าในออเดอร์ กรุณาเพิ่มสินค้า</div>
        <button
          matRipple
          class="btn btn-primary min-w-120px my-2"
          (click)="openModalProduct('เพิ่มสินค้า', 'add')"
          [disabled]="!isEditField">
          <span class="icon-16 icon-white material-symbols-outlined fill"> add_circle </span>
          เพิ่มสินค้า
        </button>
      </div>
      }
    </div>

    @if (errorProductEmpty) {
    <div class="text-danger font-14 mb-3">กรุณาเพิ่มสินค้าในออเดอร์</div>
    }
    <!-- ~ -->
    @if (products.length > 0) {
    <button
      matRipple
      class="btn btn-primary min-w-120px mt-1 mb-3"
      (click)="openModalProduct('เพิ่มสินค้า', 'add')"
      [disabled]="!isEditField">
      <span class="icon-20 material-symbols-outlined fill" [class.icon-white]="!isEditField">
        add_circle
      </span>
      เพิ่มสินค้า
    </button>
    }

    <div class="line mt-2"></div>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="col-12 col-lg-12 mb-3">
            <span class="title-blue">ข้อมูลการจัดส่ง</span>
          </div>

          <div class="col-12 col-lg-6">
            <label
              class="form-label"
              [class.text-danger]="form.controls['transport_id'].touched && form.controls['transport_id'].invalid">
              การจัดส่ง <span class="text-danger">*</span>
            </label>

            <app-select
              [control]="form.controls['transport_id']"
              [datas]="list.transport"
              fieldValue="id"
              placeholder="เลือก"
              [disabled]="!isEditField"
              (selectionChange)="form.controls['transport_other_name'].reset()">
              <ng-template #optionRef let-option>
                <div>{{ option.name }}</div>
              </ng-template>
              @if (form.controls['transport_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              }@else if (form.controls['transport_id'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['transport_id'].errors?.['formApi']}}</ng-container
              >
              }
            </app-select>
          </div>

          <div class="col-12 col-lg-6">
            <label class="form-label"> ค่าจัดส่ง </label>
            <app-input-number
              [control]="form.controls['shipping_cost']"
              placeholder="0.00"
              unit="บาท"
              [min]="0"
              [disabled]="!isEditField"
              (onInput)="resetPayment()">
              @if (form.controls['shipping_cost'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['shipping_cost'].hasError('max')) {
              <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
              }@else if (form.controls['shipping_cost'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['shipping_cost'].errors?.['formApi']}}</ng-container
              >
              }
            </app-input-number>
          </div>
          @if (form.controls['transport_id'].value == TRANSPORT_ID.OTHER) {
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-6">
                <app-input
                  [control]="form.controls['transport_other_name']"
                  placeholder="กรอกการจัดส่ง"
                  [disabled]="!isEditField">
                  @if (form.controls['transport_other_name'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  } @else if (form.controls['transport_other_name'].hasError('maxlength')) {
                  <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
                  }@else if (form.controls['transport_other_name'].hasError('formApi')) {
                  <ng-container error
                    >{{ form.controls['transport_other_name'].errors?.['formApi']}}</ng-container
                  >
                  }
                </app-input>
              </div>
            </div>
          </div>
          }

          <div class="col-12 col-lg-6">
            <label class="form-label"> หมายเหตุ </label>
            <app-text-area
              [control]="form.controls['remark']"
              remainingText="500"
              maxlength="500"
              [disabled]="!isEditField"></app-text-area>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="col-12 col-lg-12 mb-3">
          <span class="title-blue">ข้อมูลการชำระเงิน</span>
        </div>
        <div class="col-12 col-lg-12">
          <label class="form-label"> ส่วนลดเพิ่มเติม </label>
          <app-input-number
            [control]="form.controls['discount']"
            placeholder="0.00"
            unit="บาท"
            [min]="0"
            [disabled]="!isEditField"
            (onInput)="resetPayment()">
            @if (form.controls['shipping_cost'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['shipping_cost'].hasError('max')) {
            <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
            }@else if (form.controls['shipping_cost'].hasError('formApi')) {
            <ng-container error
              >{{ form.controls['shipping_cost'].errors?.['formApi']}}</ng-container
            >
            }
          </app-input-number>
        </div>
        <div class="col-12 col-lg-12 bg-gray">
          <div class="d-flex justify-content-between">
            <span>ราคารวมสินค้า</span>
            <span> {{ formatPrice(totalProduct.totalPrice) }} บาท </span>
          </div>
          <div class="d-flex justify-content-between">
            <span>ค่าจัดส่ง</span>
            <span>{{ formatPrice(shippingCost) }} บาท</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>ส่วนลดเพิ่มเติม</span>
            <span>{{ formatPrice(discount) }} บาท</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>รหัสส่วนลด</span>
            <span>{{ formatPrice(discountCodePrice) }} บาท</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>ส่วนลดเครดิต</span>
            <span>{{ formatPrice(creditCodePrice) }} บาท</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>ส่วนลดกะรัต </span>
            <span>{{ formatPrice(caratCodePrice) }} บาท</span>
          </div>
          <div class="d-flex justify-content-between">
            <b>ยอดรวมสุทธิ</b>
            <b class="blue">{{ formatPrice(sumTotal) }} บาท</b>
          </div>
          <div class="line-address my-2"></div>
          <div class="d-flex justify-content-between">
            <span>กะรัตที่ได้</span>
            <span>{{ formatPriceAutoDecimal(caratAmount) }} กะรัต</span>
          </div>
        </div>
      </div>
    </div>

    <div class="line mt-3"></div>
    <div class="d-flex justify-content-end">
      <button matRipple class="btn btn-outline-secondary btn-md me-2" (click)="toList()">
        ยกเลิก
      </button>
      <button
        matRipple
        class="btn btn-primary btn-md"
        (click)="onSave()"
        [disabled]="!(isCreate || isEdit && dataManage?.action?.edit)">
        บันทึก
      </button>
    </div>
  </div>
</div>
