import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { Loading } from '../../../globals'
import { imports } from '../../../imports'
import { MatPaginatorModule } from '@angular/material/paginator'
import { OrderService, ProductService } from '../../../services'
import { DownloadFileBlob, Pagination, formatPrice, formatPriceAutoDecimal } from '../../../helpers'
import { TextTooltipComponent } from '../../../components/text-tooltip/text-tooltip'

@Component({
  selector: 'app-modal-upload-file',
  templateUrl: './modal-upload-file.html',
  styleUrls: ['./modal-upload-file.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatPaginatorModule,
    TextTooltipComponent,
  ],
})
export class ModalUploadFileComponent {
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  file: null | File = null
  fileErrorMessage = ''
  uploadError: any
  acceptFileTypes: any = ['xlsx']
  orderList: any[] = []
  readonly paginationOrder = new Pagination({
    pageSize: 10,
  })

  get pageList() {
    return this.orderList.filter((d: any, i: number) => {
      return i >= this.paginationOrder.indexData.min && i <= this.paginationOrder.indexData.max
    })
  }

  constructor(
    public dialogRef: MatDialogRef<ModalUploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public orderService: OrderService
  ) {}

  onClose(): void {
    this.dialogRef.close()
  }

  downloadTemplate() {
    const payload = {}

    this.loading.start()
    this.orderService.templateOrder(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `template-import-orders.xlsx`)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  dragOver(event: Event) {
    event.preventDefault()
  }

  drop(event: any) {
    event.preventDefault()
    this.onChangeUpload(event.dataTransfer)
  }

  onChangeUpload(fileUplaod: any) {
    const file = fileUplaod.files[0]

    fileUplaod.value = ''
    this.file = null
    this.uploadError = null
    this.fileErrorMessage = ''
    if (file) {
      const typeName = file.name.split('.')[1]
      if (!this.acceptFileTypes.some((d: any) => d.includes(typeName))) {
        this.fileErrorMessage = 'ไฟล์ต้องเป็นนามสกุล .xlsx กรุณาอัปโหลดไฟล์ใหม่'
        return
      }

      // 1MB = 1048576
      if (file.size > 10485760) {
        this.fileErrorMessage = 'ไฟล์ต้องไม่เกิน 10 MB กรุณาอัปโหลดไฟล์ใหม่'
        return
      }

      this.file = file
    }
  }

  checkImport() {
    if (this.file) {
      const payload = new FormData()
      payload.append('file', this.file)

      this.loading.start()
      this.orderService.checkImportOrder(payload).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.orderList = res.data
            this.paginationOrder.setFromResponse({
              page: 1,
              total_record: this.orderList.length,
            })
          } else {
            this.uploadError = res.errors
          }
        }
        this.loading.stop()
      })
    }
  }

  resetUpload() {
    this.file = null
    this.uploadError = null
  }

  onSaveUpload(): void {
    if (this.file) {
      const payload = new FormData()
      payload.append('file', this.file)

      this.loading.start()
      this.orderService.importOrder(payload).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
            this.dialogRef.close(true)
          } else {
            this.uploadError = res.errors
            if (res.status === 0) {
              this.customSnackBar.failSave()
            }
          }
        }
        this.loading.stop()
      })
    }
  }
}
