import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import {
  ACTION_ACCESS,
  APP_PERMISSION,
  FilterTableCustomer,
  Loading,
  Profile,
} from './../../../../globals'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { MatPaginator } from '@angular/material/paginator'
import { ModalDownloadFileComponent } from '../../../../components/modal-download-file/modal-download-file'
import { SearchFilterComponent } from './search-filter/search-filter'
import { CheckboxAllTableComponent } from '../../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../../components/checkbox-table/checkbox-table.component'
import { ModalUploadFileComponent } from './modal-upload-file/modal-upload-file'
import { MatTooltipModule } from '@angular/material/tooltip'
import { CustomerService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import {
  DownloadFileBlob,
  Moment,
  Pagination,
  channelIcon,
  currenTier,
  formatPrice,
} from '../../../../helpers'
import { imports } from '../../../../imports'
import { TextTooltipComponent } from '../../../../components/text-tooltip/text-tooltip'
@Component({
  selector: 'app-tab-customer',
  standalone: true,
  imports: [
    ...imports,
    MatTooltipModule,
    MatTabsModule,
    ModalLeaveComponent,
    MatPaginator,
    ModalDownloadFileComponent,
    SearchFilterComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    ModalUploadFileComponent,
    TextTooltipComponent,
  ],
  templateUrl: './tab-customer.html',
  styleUrls: ['./tab-customer.scss'],
})
export class TabCustomerComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly channelIcon = channelIcon as any
  readonly currenTier = currenTier

  readonly paginationCustomer = new Pagination({
    sortName: 'code',
  })
  customerList: any[] = []
  itemChecked = new Set<any>()

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public customerService: CustomerService,
    public filterTableCustomer: FilterTableCustomer
  ) {}

  ngOnInit(): void {
    this.getCustomerList()
  }

  getCustomerList() {
    const pagination = this.paginationCustomer.get()
    const dataFilter = this.filterTableCustomer.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.customerService.getCustomerList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customerList = res.data.records.map((d: any) => {
            return {
              ...d,
              // currenTier: currenTier(this.filterTableCustomer.list.tier, d.total_price),
            }
          })
          this.paginationCustomer.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const pagination = this.paginationCustomer.get()
    const dataFilter = this.filterTableCustomer.getData()
    dataFilter.filter.ids = this.itemChecked.size > 0 ? [...this.itemChecked.values()] : undefined

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }

    this.loading.start()
    this.customerService.exportCustomer(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `customers.xlsx`)
          this.itemChecked.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  openModalUploadFile(title: string) {
    const dialogRef = this.dialog.open(ModalUploadFileComponent, {
      disableClose: true,
      data: {
        title,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCustomerList()
      }
    })

    return dialogRef
  }
}
