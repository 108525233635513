<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toList()">
      สินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    @if(isCreate) {
    <div class="breadcrumb-text">เพิ่มสินค้า</div>
    } @else if(isView) {
    <div class="breadcrumb-text">ข้อมูลสินค้า</div>
    } @else if(isEdit) {
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toView()">
      ข้อมูลสินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไขสินค้า</div>
    }
  </div>

  @if(isView) {
  <div class="title-wrapper">ข้อมูลสินค้า</div>
  } @else {
  <div class="title-wrapper">{{ isCreate ? 'เพิ่มสินค้า' : 'แก้ไขสินค้า' }}</div>
  }

  <div class="page-content-inner mb-3">
    <div class="row mb-4">
      <div class="col-12 col-lg-5">
        @if(isView) {
        <div class="title-blue">ข้อมูลสินค้า</div>
        } @else {
        <div class="title-blue">รูปภาพ</div>
        }
        <div class="mt-3 font-14">รูปภาพหน้าปก</div>
        <div class="my-2">
          <app-upload-image-profile
            class="my-sm-3 mb-2"
            [type]="isView ? 'view' : 'edit'"
            [logoDefault]="imageCover?.url"
            (upload)="uploadImageCover($event)"
            (onDelete)="deleteImageCover()"
            imageDefault="/assets/images/default-image-product.png"></app-upload-image-profile>

          <div class="mt-2">
            <span class="font-14"
              >รูปภาพเพิ่มเติม
              <span class="gray">(จำนวน {{ imageDetails.length }})</span>
            </span>
            <app-upload-image-more
              [type]="isView ? 'view' : 'edit'"
              [control]="form.controls.files"
              (upload)="form.controls.files.setValue($event)"></app-upload-image-more>
          </div>
          @if (!isView) {
          <div class="font-14 gray">หมายเหตุ:</div>
          <div>
            <ul class="font-14 gray">
              <li>ขนาดรูปที่แนะนำ 1500px x 2000px (อัตราส่วน 3:4)</li>
              <li>ขนาดไฟล์รูปไม่เกิน 30 Mb</li>
            </ul>
          </div>
          }
        </div>
      </div>
      <div class="col-12 col-lg-7">
        @if (isView) {
        <div class="d-flex flex-wrap justify-content-end">
          @if (dataManage?.action?.delete) {
          <button matRipple (click)="confirmDelete(dataManage)" class="btn btn-outline-danger me-2">
            <span class="icon material-symbols-outlined fill">delete</span>
            ลบข้อมูล
          </button>
          } @if (dataManage?.action?.edit) {
          <button
            matRipple
            class="btn btn-primary"
            [routerLink]="['/product/edit/' + dataManage.id]">
            <span class="icon material-symbols-outlined fill">edit</span>
            แก้ไขข้อมูล
          </button>
          }
        </div>
        } @else {
        <div class="title-blue">ข้อมูลสินค้า</div>
        }

        <div class="row mt-3">
          <div class="col-12 col-lg-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['code'].touched && form.controls['code'].invalid">
              รหัสสินค้า @if (!isView) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (!isView) {
            <app-input [control]="form.controls['code']" (onChange)="validateCustom()">
              @if (form.controls['code'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['code'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 15 ตัวอักษร</ng-container>
              } @else if (form.controls['code'].hasError('duplicate')) {
              <ng-container error>รหัสสินค้าซ้ำกัน</ng-container>
              } @else if (form.controls['code'].hasError('formApi')) {
              <ng-container error>{{ form.controls['code'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.code }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> รหัสสินค้าจริง </label>

            @if (!isView) {
            <app-input [control]="form.controls['real_code']">
              @if (form.controls['code'].hasError('formApi')) {
              <ng-container error>{{ form.controls['code'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.real_code || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
              ชื่อสินค้า @if (!isView) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (!isView) {
            <app-input [control]="form.controls['name']">
              @if (form.controls['name'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
              } @else if (form.controls['name'].hasError('formApi')) {
              <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.name }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> หมวดหมู่ </label>
            @if (!isView) {
            <app-select
              [control]="form.controls['product_category_id']"
              [datas]="list.gategory"
              fieldValue="id"
              [search]="['name']"
              placeholder="เลือก"
              empty="ไม่มีหมวดหมู่">
              <ng-template #optionRef let-option> {{ option.name }} </ng-template>
              @if (form.controls['product_category_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              } @else if (form.controls['product_category_id'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['product_category_id'].errors?.['formApi']}}</ng-container
              >
              }
            </app-select>
            } @else {
            <div class="label-view">{{ dataManage?.product_category?.name || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> แบรนด์ </label>
            @if (!isView) {
            <app-select
              [control]="form.controls['product_brand_id']"
              [datas]="list.brand"
              fieldValue="id"
              [search]="['name']"
              placeholder="เลือก"
              empty="ไม่มีแบรนด์">
              <ng-template #optionRef let-option> {{ option.name }} </ng-template>
              @if (form.controls['product_brand_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              } @else if (form.controls['product_brand_id'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['product_brand_id'].errors?.['formApi']}}</ng-container
              >
              }
            </app-select>
            } @else {
            <div class="label-view">{{ dataManage?.product_brand?.name || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> สี </label>
            @if (!isView) {
            <app-input [control]="form.controls['color']">
              @if (form.controls['color'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.color || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> ขนาด </label>
            @if (!isView) {
            <app-input [control]="form.controls['size']">
              @if (form.controls['size'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.size || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['selling_price'].touched && form.controls['selling_price'].invalid">
              ราคาขาย @if (!isView) {
              <span class="text-danger">*</span>
              }
            </label>
            @if (!isView) {
            <app-input-number
              [control]="form.controls['selling_price']"
              placeholder="0.00"
              [min]="0"
              unit="บาท">
              @if (form.controls['selling_price'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['selling_price'].hasError('max')) {
              <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
              } @else if (form.controls['selling_price'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['selling_price'].errors?.['formApi']}}</ng-container
              >
              }
            </app-input-number>
            } @else {
            <div class="title-blue label-view">
              {{ formatPrice(dataManage?.selling_price || 0) }} บาท
            </div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> บาร์โค้ด </label>

            @if (!isView) {
            <app-input [control]="form.controls['barcode']">
              @if (form.controls['barcode'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['barcode'].hasError('formApi')) {
              <ng-container error>{{ form.controls['barcode'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.barcode || '-' }}</div>
            }
          </div>

          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> รายละเอียดสินค้า </label>

            @if (!isView) {
            <app-text-area [control]="form.controls['detail']"></app-text-area>
            } @else {
            <div class="label-view">{{ dataManage?.detail || '-' }}</div>
            }
          </div>
        </div>

        <div class="title-blue mt-3">รายละเอียดเพิ่มเติม</div>
        <div class="row mt-3">
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> สินค้าสำหรับเพศ </label>
            @if (!isView) {
            <app-select
              [control]="form.controls['gender']"
              [datas]="list.gender"
              fieldValue="id"
              placeholder="เลือก">
              <ng-template #optionRef let-option> {{ option.name }} </ng-template>
            </app-select>
            } @else {
            <div class="label-view">{{ gender ? gender.name : '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> แท็กสินค้า </label>
            @if (!isView) {
            <app-input-tag [control]="form.controls['tags']"></app-input-tag>
            } @else {
            <div class="d-flex flex-wrap gap-2">
              @for (item of dataManage?.tags; track $index) {
              <label class="tag-name"> {{ item.tag.name }} </label>
              }
              <!-- ~ -->
              @if (!dataManage?.tags?.length) { - }
            </div>
            }
          </div>
        </div>

        <div class="title-blue mt-3">การร่วมรายการ</div>
        <div class="mt-3">
          @if (!isView) {
          <label class="form-label {{ isView && 'neutral-500'}}">
            สินค้าเข้าร่วมรายการสะสมกะรัต
          </label>
          <div [formGroup]="form">
            <mat-checkbox formControlName="is_carat">เข้าร่วมรายการ</mat-checkbox>
          </div>
          } @else {
          <div class="label-view">
            <div class="d-flex align-items-center">
              @if (dataManage?.is_carat) {
              <span class="icon material-symbols-outlined fill green me-2">check_circle</span>
              สินค้าเข้าร่วมรายการสะสมกะรัต } @else {
              <span class="icon material-symbols-outlined fill red me-2">cancel</span>
              สินค้าไม่เข้าร่วมรายการสะสมกะรัต }
            </div>
          </div>
          }
        </div>

        <div class="title-blue mt-4">การใช้งาน</div>
        <div class="mt-2">
          <label class="form-label {{ isView && 'neutral-500'}}"> สถานะการใช้งาน </label>
          <div>
            @if (!isView) {
            <mat-slide-toggle
              hideIcon="true"
              color="primary"
              class="slide-toggle"
              [formControl]="form.controls.is_active">
              ใช้งาน
            </mat-slide-toggle>
            }@else {
            <div class="label-view">
              <app-status-user [status]="dataManage?.is_active ? 'active' : 'inactive'">
                {{ form.controls['is_active'].value ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
              </app-status-user>
            </div>
            }
          </div>
        </div>
      </div>
    </div>

    @if (isCreate) {
    <div class="line"></div>
    <div class="title-blue">ข้อมูลสต๊อกสินค้า</div>
    <div class="row mt-3">
      <div class="col-12 col-lg-4">
        <label class="form-label"> สต๊อก </label>
        <app-input-number
          [control]="form.controls['stock']"
          placeholder="0"
          [min]="0"
          [decimal]="0">
          @if (form.controls['stock'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000</ng-container>
          }@else if (form.controls['stock'].hasError('formApi')) {
          <ng-container error>{{ form.controls['stock'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>
      <div class="col-12 col-lg-4">
        <label class="form-label"> ผู้ขาย </label>
        <app-select
          [control]="form.controls['supplier_id']"
          [datas]="list.supplier"
          fieldValue="id"
          [search]="['name']"
          placeholder="เลือก"
          empty="ไม่มีผู้ขาย">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
          @if (form.controls['supplier_id'].hasError('formApi')) {
          <ng-container error>{{ form.controls['supplier_id'].errors?.['formApi']}}</ng-container>
          }
        </app-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <label class="form-label"> ต้นทุนต่อหน่วย </label>
        <app-input-number
          [control]="form.controls['cost_price']"
          placeholder="0.00"
          [min]="0"
          unit="บาท">
          @if (form.controls['cost_price'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
          }@else if (form.controls['cost_price'].hasError('formApi')) {
          <ng-container error>{{ form.controls['cost_price'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>
      <div class="col-12 col-lg-4">
        <label class="form-label"> ต้นทุนรวม </label>
        <app-input
          [value]="formatPrice(totalCostPrice() || 0)"
          placeholder="0.00"
          unit="บาท"
          [disabled]="true"></app-input>
      </div>
    </div>
    } @if (form.controls['is_sub_product'].value) {
    <div class="line"></div>
    <div class="title-blue">สินค้าย่อย</div>
    } @if (isCreate || (isEdit && this.dataManage && !this.dataManage.details.length)) {
    <div [formGroup]="form">
      <mat-checkbox formControlName="is_sub_product" (change)="changeIsSubProduct($event)"
        >เพิ่มสินค้าย่อย</mat-checkbox
      >
    </div>
    } @if (form.controls['is_sub_product'].value) {
    <div class="table-responsive-detail mt-3">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            <th width="65">ลำดับ</th>
            <th width="90">รูปสินค้า</th>
            <th width="210">รหัสสินค้า @if (!isView) {<span class="text-danger">*</span>}</th>
            <th width="210">รหัสสินค้าจริง</th>
            <th width="340">ชื่อสินค้า @if (!isView) {<span class="text-danger">*</span>}</th>
            <th width="110">สี</th>
            <th width="110">ขนาด</th>
            <th width="140">ราคาขาย (บาท) @if (!isView) {<span class="text-danger">*</span>}</th>
            @if (!isView) {
            <!-- ~ -->
            @if (isCreate) {
            <th width="100">สต๊อก</th>
            <th width="160">ต้นทุนต่อหน่วย (บาท)</th>
            <th width="150">ต้นทุนรวม (บาท)</th>
            }
            <th width="200">บาร์โค้ด</th>
            }
            <!-- ~ -->
            @if (!isView && sub_product_list.controls.length > 1) {
            <th width="100"></th>
            }
          </tr>
        </thead>
        <tbody>
          @for (item of sub_product_list.controls; track item; let index = $index;) {
          <tr>
            <td>
              <div class="pb-3">{{index + 1}}</div>
            </td>
            <td>
              <app-upload-image-table
                [type]="isView ? 'view' : 'edit'"
                [logoDefault]="item.controls['files'].value[0]?.url"
                (upload)="item.controls['files'].setValue($event)"></app-upload-image-table>
            </td>
            <td>
              @if (!isView) {
              <app-input [control]="item.controls['code']" (onChange)="validateCustom()">
                @if (item.controls['code'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['code'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 15 ตัวอักษร</ng-container>
                } @else if (item.controls['code'].hasError('duplicate')) {
                <ng-container error>รหัสสินค้าซ้ำกัน</ng-container>
                } @else if (item.controls['code'].hasError('formApi')) {
                <ng-container error>{{ item.controls['code'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
              } @else { {{ item.value.code }} }
            </td>
            <td>
              @if (!isView) {
              <app-input [control]="item.controls['real_code']" (onChange)="validateCustom()">
                @if (item.controls['real_code'].hasError('formApi')) {
                <ng-container error>
                  {{ item.controls['real_code'].errors?.['formApi']}}
                </ng-container>
                }
              </app-input>
              } @else { {{ item.value.real_code || '-' }} }
            </td>
            <td>
              @if (!isView) {
              <app-input [control]="item.controls['name']">
                @if (item.controls['name'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['name'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
                } @else if (item.controls['name'].hasError('formApi')) {
                <ng-container error>{{ item.controls['name'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
              } @else { {{ item.value.name }} }
            </td>
            <td>
              @if (!isView) {
              <app-input [control]="item.controls['color']">
                @if (item.controls['color'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (item.controls['color'].hasError('formApi')) {
                <ng-container error>{{ item.controls['color'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
              } @else { {{ item.value.color || '-' }} }
            </td>
            <td>
              @if (!isView) {
              <app-input [control]="item.controls['size']">
                @if (item.controls['color'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (item.controls['color'].hasError('formApi')) {
                <ng-container error>{{ item.controls['color'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
              } @else { {{ item.value.size || '-' }} }
            </td>
            <td>
              @if (!isView) {
              <app-input-number [control]="item.controls['selling_price']" [min]="0">
                @if (item.controls['selling_price'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['selling_price'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                } @else if (item.controls['selling_price'].hasError('formApi')) {
                <ng-container error
                  >{{ item.controls['selling_price'].errors?.['formApi']}}</ng-container
                >
                }
              </app-input-number>
              } @else { {{ formatPrice(item.value.selling_price || 0) }} }
            </td>
            @if (!isView) {
            <!-- ~ -->
            @if (isCreate) {
            <td>
              <app-input-number [control]="item.controls['stock']" [min]="0">
                @if (item.controls['stock'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000</ng-container>
                } @else if (item.controls['stock'].hasError('formApi')) {
                <ng-container error>{{ item.controls['stock'].errors?.['formApi']}}</ng-container>
                }
              </app-input-number>
            </td>
            <td>
              <app-input-number [control]="item.controls['cost_price']" [min]="0">
                @if (item.controls['cost_price'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                } @else if (item.controls['cost_price'].hasError('formApi')) {
                <ng-container error
                  >{{ item.controls['cost_price'].errors?.['formApi']}}</ng-container
                >
                }
              </app-input-number>
            </td>
            <td>
              <app-input
                [value]="formatPrice(totalCostPriceDetail(index))"
                [disabled]="true"></app-input>
            </td>
            }
            <td>
              <app-input [control]="item.controls['barcode']">
                @if (item.controls['barcode'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (item.controls['barcode'].hasError('formApi')) {
                <ng-container error>{{ item.controls['barcode'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
            </td>
            }
            <!-- ~ -->
            @if (!isView && sub_product_list.controls.length > 1) {
            <td>
              @if (index != 0) {
              <button
                matRipple
                class="btn btn-transparent-outline py-1 mb-3"
                (click)="delSubProduct(index)">
                <span class="icon icon-20 material-symbols-outlined fill text-danger">delete</span>
              </button>
              }
            </td>
            }
          </tr>
          }
        </tbody>
      </table>
    </div>
    @if (!isView) {
    <button matRipple class="btn btn-outline-secondary mt-3" (click)="addSubProduct()">
      <span class="icon material-symbols-outlined fill">add_circle</span>
      เพิ่มสินค้าย่อย
    </button>
    }
    <!-- ~ -->
    }
    <!-- ~ -->
    @if (!isView && profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.CREATE,
    ACTION_ACCESS.UPDATE])) {
    <div class="footer-button-wrapper d-flex justify-content-end mt-3">
      <button matRipple class="btn btn-outline-secondary btn-md" (click)="toList()">ยกเลิก</button>
      <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
    </div>
    }
  </div>

  @if(isView && dataManage) {
  <app-product-stock
    [product]="dataManage"
    [imageCover]="imageCover"
    (done)="productStockLog.getProductStockLogList()"></app-product-stock>

  <app-product-seller-list [product]="dataManage"></app-product-seller-list>

  <app-product-stock-log #productStockLog [product]="dataManage"></app-product-stock-log>

  <app-product-return-log #productReturnLog [product]="dataManage"></app-product-return-log>
  } @if (isView) {
  <div class="line mt-4"></div>
  <div class="d-flex justify-content-end mt-3">
    <button matRipple class="btn btn-outline-secondary min-w-100px" [routerLink]="['/product']">
      กลับสู่หน้าสินค้า
    </button>
  </div>
  }
</div>
