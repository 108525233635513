<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="container gx-0">
      <div class="row gy-2">
        <div class="col-12 col-sm-6">
          <label
            class="label"
            [class.text-danger]="form.controls['bank_id'].touched && form.controls['bank_id'].invalid">
            ธนาคาร
            <span class="text-danger">*</span>
          </label>
          <app-select
            [control]="form.controls['bank_id']"
            [datas]="list.bank"
            fieldValue="id"
            [search]="['name']"
            placeholder="เลือก">
            <ng-template #optionRef let-option>
              <div class="d-flex align-items-center gap-2">
                <img
                  [alt]="option.name"
                  src="/assets/images/logos/banks/{{ option.code }}.png"
                  width="20" />
                {{ option.name }}
              </div>
            </ng-template>
            @if (form.controls['bank_id'].hasError('required')) {
            <ng-container error>กรุณาเลือกข้อมูล</ng-container>
            }
          </app-select>
        </div>

        <div class="col-12 col-sm-6">
          <label
            class="label"
            [class.text-danger]="form.controls['account_name'].touched && form.controls['account_name'].invalid">
            ชื่อบัญชี
            <span class="text-danger">*</span>
          </label>
          <app-input [control]="form.controls['account_name']">
            @if (form.controls['account_name'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['account_name'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            } @else if (form.controls['account_name'].hasError('formApi')) {
            <ng-container error
              >{{ form.controls['account_name'].errors?.['formApi']}}</ng-container
            >
            }
          </app-input>
        </div>

        <div class="col-12 col-sm-6">
          <label
            class="label"
            [class.text-danger]="form.controls['branch_name'].touched && form.controls['branch_name'].invalid">
            ชื่อสาขา
          </label>
          <app-input [control]="form.controls['branch_name']">
            @if (form.controls['branch_name'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['branch_name'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            } @else if (form.controls['branch_name'].hasError('formApi')) {
            <ng-container error>{{ form.controls['branch_name'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div class="col-12 col-sm-6">
          <label
            class="label"
            [class.text-danger]="form.controls['branch_code'].touched && form.controls['branch_code'].invalid">
            รหัสสาขา
          </label>
          <app-input [control]="form.controls['branch_code']">
            @if (form.controls['branch_code'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['branch_code'].hasError('pattern')) {
            <ng-container error>กรุณากรอกตัวเลข (0-9) เท่านั้น</ng-container>
            } @else if (form.controls['branch_code'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            } @else if (form.controls['branch_code'].hasError('formApi')) {
            <ng-container error>{{ form.controls['branch_code'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div class="col-12 col-sm-6">
          <label
            class="label"
            [class.text-danger]="form.controls['account_number'].touched && form.controls['account_number'].invalid">
            เลขที่บัญชี
            <span class="text-danger">*</span>
          </label>
          <app-input [control]="form.controls['account_number']">
            @if (form.controls['account_number'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['account_number'].hasError('pattern')) {
            <ng-container error>เลขที่บัญชีไม่ถูกต้อง</ng-container>
            }@else if (form.controls['account_number'].hasError('formApi')) {
            <ng-container error>
              {{ form.controls['account_number'].errors?.['formApi']}}
            </ng-container>
            }
          </app-input>
        </div>

        <div class="col-12 col-sm-6">
          <label
            class="label"
            [class.text-danger]="form.controls['type_id'].touched && form.controls['type_id'].invalid">
            ประเภท
          </label>
          <app-select
            [control]="form.controls['type_id']"
            [datas]="list.bankType"
            fieldValue="id"
            placeholder="เลือก">
            <ng-template #optionRef let-option>{{ option.name }} </ng-template>
            @if (form.controls['type_id'].hasError('required')) {
            <ng-container error>กรุณาเลือกข้อมูล</ng-container>
            } @else if (form.controls['type_id'].hasError('formApi')) {
            <ng-container error>{{ form.controls['type_id'].errors?.['formApi']}}</ng-container>
            }
          </app-select>
        </div>

        <div class="col-12 col-sm-6" [formGroup]="form">
          <mat-checkbox formControlName="is_primary" (change)="changePrimary()">
            ตั้งเป็นธนาคารหลัก
          </mat-checkbox>

          <!-- @if (form.controls['is_primary'].hasError('required')) {
          <ng-container error>กรุณาเลือกธนาคารหลัก</ng-container>
          } -->
        </div>

        <div class="col-12 col-sm-6">
          <label class="label"> สถานะการใช้งาน </label>
          <div class="mt-2">
            <mat-slide-toggle
              hideIcon="true"
              color="primary"
              class="slide-toggle"
              [formControl]="form.controls.is_active">
              ใช้งาน
            </mat-slide-toggle>
          </div>
        </div>

        <div class="col-12 col-sm-6">
          <label class="label"> QR รับชำระเงิน </label>

          <app-upload-image-payment-cancel
            [imageDefault]="dataManage?.qr?.url"
            (upload)="form.controls.qr_id.setValue($event)"
            (onDelete)="form.controls.qr_id.reset()"></app-upload-image-payment-cancel>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button
      matRipple
      class="btn btn-primary btn-md"
      [disabled]="form.invalid"
      (click)="onConfirm()">
      บันทึก
    </button>
  </div>
</mat-dialog-actions>
