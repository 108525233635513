import { Component, Input, SimpleChanges } from '@angular/core'
import { Tooltip } from 'chart.js'
import {
  ApexAnnotations,
  ApexDataLabels,
  ApexYAxis,
  NgApexchartsModule,
  YAxisAnnotations,
} from 'ng-apexcharts'
import { formatNoDecimal, formatNumberToShort, formatPrice } from '../../../../helpers'
import { imports } from '../../../../imports'
;(Tooltip.positioners as any).myCustomPositioner = function (elements: any, eventPosition: any) {
  // A reference to the tooltip model
  const tooltip = this

  /* ... */

  return {
    x: 0,
    y: 0,
    // You may also include xAlign and yAlign to override those tooltip options.
  }
}

@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [...imports, NgApexchartsModule],
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss'],
})
export class ChartComponent {
  @Input() data: any = []
  @Input() titleChart: any
  @Input() categories: any
  @Input() color: any
  @Input() isPercent: boolean = false
  @Input() min: number = 0
  @Input() max: number = 240
  @Input() minAbs: number = 0
  @Input() maxAbs: number = 0
  @Input() trueMax: number = 240
  @Input() stepSize: number = 10
  @Input() name?: any
  @Input() average: any

  readonly formatPrice = formatPrice
  readonly formatNoDecimal = formatNoDecimal

  chartOptions: any
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.setData()
    }, 1000)
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.setData()
    }, 1000)
  }

  // ngAfterViewInit(): void {
  //   const ctx = document.getElementById('chart-id') as any
  //   if (ctx) {
  //     // const datasets = [
  //     //   {
  //     //     type: 'bar',
  //     //     label: 'rainfall probability',
  //     //     data: [10, 20, 30, 40],
  //     //   },
  //     //   // {
  //     //   //   type: 'line',
  //     //   //   label: 'temperature',
  //     //   //   data: [50, 50, 50, 50],
  //     //   //   borderColor: '#EB6E4B',
  //     //   //   backgroundColor: '#EB6E4B',
  //     //   // },
  //     // ]

  //     const data = {
  //       labels: ['now', '9am', '10am', '11am'],
  //       datasets: [
  //         {
  //           label: 'rainfall probability',
  //           data: [12, 22, -3799999, 3899999],
  //           backgroundColor: 'rgba(54, 162, 235, 0.5)',
  //           borderColor: 'rgba(54, 162, 235, 1)',
  //           borderWidth: 1,
  //         },
  //       ],
  //     }
  //     const myChart = new Chart(ctx, {
  //       type: 'bar',
  //       data: data,
  //       options: {
  //         responsive: true,
  //         scales: {
  //           y: {
  //             beginAtZero: true,
  //             // max: 4400000,
  //             // min: -4400000,
  //           },
  //         },
  //         plugins: {
  //           legend: {
  //             display: false,
  //           },
  //         },
  //       },
  //       plugins: [
  //         {
  //           id: 'dataLabel',
  //           afterDatasetsDraw(chart) {
  //             const { ctx, data } = chart
  //             chart.getDatasetMeta(0).data.forEach((bar, index) => {
  //               ctx.fillStyle = '#FF0000'
  //               ctx.font = 'bold 14px Arial'
  //               ctx.textAlign = 'center'
  //               ctx.fillText((data.datasets[0].data[index] || '').toString(), bar.x, bar.y)
  //             })

  //             // chart.getDatasetMeta(0).data.forEach((bar, index) => {
  //             //   console.log('dd', data.datasets[0].data[index])

  //             //   ctx.save()
  //             //   ctx.fillStyle = '#FF0000'
  //             //   ctx.font = 'bold 14px Arial'
  //             //   ctx.textAlign = 'center'
  //             //   ctx.translate(bar.x, bar.y - 10)
  //             //   ctx.rotate(-Math.PI / 2)
  //             //   ctx.fillText((data.datasets[0].data[index] || '').toString(), 0, 0)
  //             //   ctx.restore()
  //             // })

  //             // chart.getDatasetMeta(0).data.forEach((bar, index) => {
  //             //   let value = data.datasets[0].data[index] as number
  //             //   // let fontSize = value > 100000 ? 10 : 14 // ลดขนาดฟอนต์ถ้าค่ามีขนาดใหญ่
  //             //   ctx.save()
  //             //   ctx.fillStyle = '#FF0000'
  //             //   // ctx.font = `bold ${fontSize}px Arial`
  //             //   ctx.textAlign = 'center'
  //             //   ctx.translate(bar.x, bar.y - 15) // เลื่อนตัวเลขลงเล็กน้อย
  //             //   ctx.rotate(-Math.PI / 2)
  //             //   ctx.fillText(value.toString(), 0, 0)
  //             //   ctx.restore()
  //             // })

  //             // chart.getDatasetMeta(0).data.forEach((bar: any, index) => {
  //             //   // console.log('bar', bar)

  //             //   let value = data.datasets[0].data[index] as number
  //             //   let fontSize = value > 100000 ? 10 : 14
  //             //   ctx.save()
  //             //   ctx.fillStyle = '#FF0000'
  //             //   ctx.font = `bold ${fontSize}px Arial`
  //             //   ctx.textAlign = 'center'
  //             //   ctx.translate(bar.x + bar.width / 2, Math.max(bar.y - 10, 20)) // จัดตำแหน่งให้ชิดด้านบนของแท่ง
  //             //   ctx.rotate(-Math.PI / 2) // หมุนตัวเลขขึ้นด้านบน
  //             //   ctx.fillText(value.toString(), 0, 0)
  //             //   ctx.restore()
  //             // })
  //           },
  //         },
  //       ],
  //     })
  //   }
  // }

  annotationLabel(y: number, suffix: 'm' | 'k' | '') {
    const annotationLabel: YAxisAnnotations = {
      y,
      strokeDashArray: 0,
      borderColor: '#dfdfdf',
      // text: '0',
      // offsetX: -20,
      label: {
        borderColor: 'transparent',
        position: 'left',
        borderWidth: 2,
        offsetX: -26,
        offsetY: 7,
        style: {
          // color: '#000000',
          background: '#ffffff',
        },
        text: y.toString(),
      },
    }

    return annotationLabel
  }

  setData() {
    const annotations: ApexAnnotations = {
      yaxis: [this.annotationLabel(0, '')],
    }

    const _minAbs = Math.min(this.minAbs, this.maxAbs)
    const _maxAbs = Math.max(this.minAbs, this.maxAbs)
    // console.log('_minAbs, _maxAbs', _minAbs, _maxAbs)
    let base = Number(
      (_maxAbs * 0.1)
        .toFixed(0)
        .split('')
        .reduce((nums: string, ns, i) => {
          // console.log('ns', ns)

          if (i == 0) {
            nums += '5'
          } else {
            nums += '0'
          }
          return nums
        }, '')
    )
    // console.log('base', base)

    let _max = 0
    let _min = 0
    while (_max < _maxAbs) {
      _max += base
      if (_min < _minAbs) {
        _min += base
      }
    }

    _max += base
    _min += base
    const minPersent = (_min * 100) / _max
    console.log('minPersent', minPersent)

    if (_max > 10000000) {
      _max += base
      if (_min > 10000000) {
        _min += base
      }

      if (parseInt(_maxAbs.toString().charAt(1)) > 5) {
        _max += base
        _min += base
      }
    }

    if (minPersent > 70) {
      _max += base
      _min = _max
    }
    let max = 0
    let min = 0

    const isMinus = this.min < 0
    if (isMinus) {
      if (this.minAbs < this.maxAbs) {
        max = _max
        min = -_min
      } else {
        max = _min
        min = -_max
      }
    } else {
      max = _max
    }

    const yaxis: ApexYAxis = {
      // forceNiceScale: true,
      // show: false,
      // floating: false,
      // decimalsInFloat: 0,
      labels: {
        // minWidth: maxValue > 1000000 ? 100 : 50,
        minWidth: 50,
        offsetX: -16,
        formatter: (val: any) => {
          if (val < 0) {
            return '-' + formatNumberToShort(Math.abs(val))
          } else {
            return formatNumberToShort(val)
          }
        },
        style: {
          colors: ['#404040'],
          fontSize: '12px',
          fontWeight: '400',
        },
      },
      // stepSize: this.stepSize / 5,
      // min: -(this.stepSize * 5),
      // max: this.stepSize * 5,
      // tickAmount,
      max: max,
      min: min,
    }

    const dataLabels: ApexDataLabels = {
      enabled: true,
      offsetY: 2,
      // textAnchor: 'start',
      style: {
        colors: ['#000000'],
        fontSize: '12px',
        fontWeight: '400',
      },
      background: {
        // enabled: true,
        // foreColor: '#000000',
        borderWidth: 1,
        padding: 0,
        // borderColor: '#000000',
      },
      formatter: function (val: any) {
        return val == 0 ? '' : formatPrice(val)
      },
    }

    const chartOptions = {
      series: this.data || null,
      chart: {
        redrawOnWindowResize: false,
        height: 600,
        type: 'bar',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        parentHeightOffset: 100,
      },
      annotations,
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
            orientation: 'vertical',
            // hideOverflowingLabels: false,
            // rangeBarGroupRows: true,
            // maxItems: 100,
            // total: {
            //   enabled: false,
            //   formatter: undefined,
            //   offsetX: 0,
            //   offsetY: 100,
            //   style: {
            //     color: '#373d3f',
            //     fontSize: '12px',
            //     fontFamily: undefined,
            //     fontWeight: 600,
            //   },
            // },
          },
          // columnWidth: this.data?.length > 1 ? '50%' : '33%',
          columnWidth: '20px',
          barHeight: '50%',
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: 'transparent',
              },
            ],
          },
        },
      },
      xaxis: {
        categories: this.categories,
        labels: {
          style: {
            colors: ['#525252'],
            fontSize: '12px',
            fontWeight: '400',
          },
          // rotate: 0,
        },
      },
      yaxis,
      dataLabels,
      title: {
        text: this.titleChart,
        style: {
          fontSize: '14px',
          fontWeight: '400',
          color: '#262626',
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        colors: this.color,
      },
      legend: {
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Kanit',
        fontWeight: 400,
        labels: {
          colors: '#525252',
        },
        markers: {
          size: 6,
          shape: 'circle',
          strokeWidth: 2,
          fillColors: this.color,
          radius: 2,
          offsetX: -8,
        },
        itemMargin: {
          horizontal: 30,
        },
      },
      stroke: {
        colors: ['transparent'],
        width: 4,
      },
    }

    this.chartOptions = chartOptions
  }
}
