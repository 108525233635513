export const LINK_LIST = {
  PRODUCT_DETAIL: 1,
  CATEGORY: 2,
  URL: 3,
  CAMPAIGN: 4,
  COLLECTION: 5,
} as const;

export const LINK_URL = {
  LINE: 'https://page.line.me/paniabangkok',
  FACEBOOK: 'https://page.line.me/paniabangkok',
  INSTRAGRAM: 'https://www.instagram.com/paniabangkok?igsh=MWVxNjE3eWlna',
  TIKTOK: 'https://www.tiktok.com/@paniabangkok?_t=8pxnO8uUsTP&_r=1',
};
