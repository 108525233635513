import { encrypt, decrypt } from './crypto'

export const setSessionStorage = (name: any, value: any) => {
  sessionStorage.setItem(name, encrypt(value))
}

export const getSessionStorage = (name: any) => {
  let item = sessionStorage.getItem(name)
  return item ? decrypt(sessionStorage.getItem(name)) : undefined
}

export const removeSessionStorage = (name: any) => {
  sessionStorage.removeItem(name)
}

export const setLocalStorage = (name: any, value: any) => {
  // console.log('setLocalStorage')
  localStorage.setItem(name, encrypt(value))
}

export const getLocalStorage = (name: any) => {
  // console.log('getLocalStorage',name)
  let item = localStorage.getItem(name)
  return item ? decrypt(localStorage.getItem(name)) : undefined
}

export const removeLocalStorage = (name: any) => {
  // console.log('removeLocalStorage')
  localStorage.removeItem(name)
}

export const SetLocalStorage = (name: any, value: any) => {
  // console.log('SetLocalStorage')
  localStorage.setItem(name, encrypt(value))
}

export const GetLocalStorage = (name: any) => {
  // console.log('GetLocalStorage',name)
  return decrypt(localStorage.getItem(name))
}

export const RemoveLocalStorage = (name: any) => {
  // console.log('RemoveLocalStorage')
  localStorage.removeItem(name)
}

export const Logout = (redirect = true) => {
  removeLocalStorage('access_token')
  removeLocalStorage('refresh_token')

  //send to extension
  // window.postMessage({
  //   key: 'logout_from_web',
  //   value: null,
  // })

  if (redirect && location.pathname != '/login') {
    location.href = '/login'
  }
}
