import { Component, Inject } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../../components/date-picker-range/date-picker-range'
import { SelectComponent } from '../../../../../components/select/select'
import { TextareaComponent } from '../../../../../components/text-area/text-area'
import { ModalLeaveComponent } from '../../../../../components/modal-leave/modal-leave'
import { imports } from '../../../../../imports'
import { CustomerService } from '../../../../../services'
import { Loading } from '../../../../../globals'
import { ValidateForm, setErrorForm } from '../../../../../helpers'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../../components/input-number/input-number'

@Component({
  selector: 'app-modal-carat',
  templateUrl: './modal-carat.html',
  styleUrls: ['./modal-carat.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputNumberComponent,
    SelectComponent,
    TextareaComponent,
  ],
})
export class ModalCaratComponent {
  form = new FormGroup({
    carat_type: new FormControl<any>(null, [Validators.required]),
    carat: new FormControl(null, [Validators.required, Validators.max(1000000)]),
    remark: new FormControl(''),
  })

  list = {
    carat_type: <any[]>[
      { value: 1, text: 'เพิ่มกะรัต' },
      { value: 2, text: 'ใช้กะรัต' },
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<ModalCaratComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public customerService: CustomerService
  ) {}

  ngOnInit() {
    this.initData()
  }

  initData() {
    this.form.reset()
  }

  onClose() {
    if (this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onConfirm() {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {
        data: true,
      },
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave(): void {
    const value = this.form.getRawValue()

    const payload = {
      carat_type: value.carat_type, //1:เพิ่มกะรัต 2:ใช้กะรัต
      carat: value.carat,
      remark: value.remark,
    }

    // return console.log(payload)

    this.loading.start()
    this.customerService
      .addCustomerCarat(this.data.dataManage.id, payload)
      .subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
            this.dialogRef.close(true)
          } else {
            if (res.errors[0]?.field) {
              setErrorForm(this.form, res.errors)
            } else {
              this.customSnackBar.failSave(res.message)
            }
          }
        }
        this.loading.stop()
      })
  }
}
