<div class="d-flex flex-wrap gap-3 justify-content-between">
  <div class="box">
    <div>
      <div class="font-12 color--neutral-500">สต๊อก</div>
      <div>{{ formatPriceAutoDecimal(summary.stock) }}</div>
    </div>
    <div class="box-color-blue">
      <span class="icon material-symbols-outlined">deployed_code</span>
    </div>
  </div>
  <div class="box">
    <div>
      <div class="font-12 color--neutral-500">ต้นทุนสต๊อก</div>
      <div>{{ formatPrice(summary.stock_cost) }}</div>
    </div>
    <div class="box-color-blue">
      <span class="icon material-symbols-outlined">trolley</span>
    </div>
  </div>
  <div class="box">
    <div>
      <div class="font-12 color--neutral-500">มูลค่าสต๊อก</div>
      <div>{{ formatPrice(summary.stock_price) }}</div>
    </div>
    <div class="box-color-blue">
      <span class="icon material-symbols-outlined">monetization_on</span>
    </div>
  </div>
  <div class="box">
    <div>
      <div class="font-12 color--neutral-500">ยอดขายวันนี้</div>
      <div>{{ formatPrice(summary.total_day_sale_price) }}</div>
    </div>
    <div class="box-color-green">
      <span class="icon material-symbols-outlined">account_balance_wallet</span>
    </div>
  </div>
  <div class="box">
    <div>
      <div class="font-12 color--neutral-500">ยอดขายเดือนนี้</div>
      <div>{{ formatPrice(summary.total_month_sale_price) }}</div>
    </div>
    <div class="box-color-green">
      <span class="icon material-symbols-outlined">monitoring</span>
    </div>
  </div>
</div>

<div class="d-flex flex-wrap gap-2 justify-content-between mt-3">
  <div class="select-wrapper d-flex flex-wrap gap-2">
    <div class="select-item">
      <app-select
        [control]="year"
        [datas]="list.year"
        fieldValue="value"
        placeholder="เลือก"
        [remove_bottom]="true">
        <ng-template #optionRef let-option> {{ option.text }} </ng-template>
      </app-select>
    </div>
    <div class="select-month">
      <app-select
        [control]="month"
        [datas]="list.month"
        fieldValue="value"
        placeholder="ทั้งหมด"
        [remove_bottom]="true">
        <ng-template #optionRef let-option> {{ option.text }} </ng-template>
      </app-select>
    </div>
    <div class="date-item">
      <app-date-picker-range
        [controlStr]="start_date"
        [controlEnd]="end_date"
        [min]="minDate"
        [max]="maxDate"
        [hideError]="true"
        [disabled]="!month.value"
        (onChange)="getSaleReport()"></app-date-picker-range>
    </div>
  </div>
  @if (profile.permissions.checkAccess([APP_PERMISSION.REPORT], [ACTION_ACCESS.VIEW])) {
  <button
    matRipple
    class="btn btn-outline-secondary"
    (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายงานยอดขาย', { message: 'คุณต้องการดาวน์โหลดไฟล์รายงานยอดขายใช่หรือไม่' })">
    <span class="icon material-symbols-outlined fill">download_2</span>
    ส่งออกไฟล์
  </button>
  }
</div>
<!-- <ag-charts-angular style="height: 100%" [options]="options"></ag-charts-angular> -->
<div class="chart-wrapper gap-3 mt-2">
  <div class="page-content-inner h-100">
    <b> ยอดขายรายปี </b>
    <div class="d-flex color-gray">
      <div class="me-4">ยอดขายรวม : {{ formatPrice(summary.total_sale_price) }} บาท</div>
      <div>ยอดกำไร : {{ formatPrice(summary.total_margin) }} บาท</div>
    </div>
    <div class="line mt-3"></div>

    <div class="chart-scroll">
      <div
        class="chart-box"
        [style]="{ 'min-width': (minWidthChart < 600 ? 600 : minWidthChart) + 'px', 'width': '100%' }">
        <!-- <ag-charts-angular
            style="height: 413px !important; display: block"
            [options]="options"></ag-charts-angular> -->

        <app-chart
          [data]="dataChart"
          titleChart=""
          [isPercent]="false"
          [color]="dataChartColor"
          [categories]="dataChartCategories"
          [min]="minChart"
          [max]="maxChart" />
      </div>
    </div>
  </div>

  <div class="page-content-inner h-100">
    <b> ยอดขายตามช่องทางโซเชียล </b>
    <div class="d-flex color-gray">
      <div class="me-4">ยอดขายรวม : {{ formatPrice(summary.total_sale_price) }} บาท</div>
    </div>
    <div class="line mt-3"></div>

    @for (item of tableList.channels; track item; let i = $index) {
    <!-- ~ -->
    @if (!item.is_total) {
    <div class="row-channel mb-3">
      <div class="box-c-name align-items-center">
        @if (channelIcon[item.id]) {
        <img src="/assets/images/icons/icon-social-{{ channelIcon[item.id] }}.svg" class="me-1" />
        }
        <div class="d-inline-block text-nowrap channel-name">{{ item.channel_name }}</div>
      </div>
      <div class="box-c-progress align-items-center">
        <div class="mt-2">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              [style]="{width: item.percent + '%', backgroundColor: '#1A9FD6'}"
              [attr.aria-valuenow]="item.percent"
              aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
          <div class="d-flex justify-content-end mt-1">
            <div class="color--neutral-500 font-14">{{ formatPrice(item.total) }}</div>
          </div>
        </div>
      </div>
    </div>
    }
    <!-- ~ -->
    }
  </div>
</div>

<div class="page-content-inner mt-3">
  <b> รายละเอียดยอดขาย </b>
  <div class="line mt-3"></div>

  <div class="table-responsive-no-height mt-3">
    <table class="table table-fixed-column table-detail">
      <thead>
        <tr>
          <th width="200" class=""></th>
          @for (col of columns; track $index) {
          <th width="160" class="text-end">{{ col.name }}</th>
          }
        </tr>
      </thead>

      <tbody>
        @for (item of tableList.channels; track $index) {
        <tr [class.title-blue]="item.is_total">
          <td>
            <app-text-tooltip>
              <span class="name-channel" [innerHTML]="item.channel_name || '-'"></span>
            </app-text-tooltip>
          </td>
          @for (col of item.columns || []; track $index) {
          <td class="text-end">{{ formatPrice(col.value) }}</td>
          }
        </tr>
        }
        <!-- ~ -->
        @for (item of tableList.expenses; track $index) {
        <tr [class.title-blue]="item.is_total">
          <td>{{ item.expense_name }}</td>
          @for (col of item.columns || []; track $index) {
          <td class="text-end">{{ formatPrice(col.value) }}</td>
          }
        </tr>
        }
      </tbody>
    </table>

    @if(!tableList.channels.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
    }
  </div>
</div>
