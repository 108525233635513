import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexNonAxisChartSeries,
  NgApexchartsModule,
  ApexTooltip,
} from 'ng-apexcharts'
import { max } from 'moment-timezone'
import { imports } from '../../../../imports'
import { animate, animation } from '@angular/animations'
import { formatNoDecimal, formatPrice } from '../../../../helpers'

@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [...imports, NgApexchartsModule],
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss'],
})
export class ChartComponent {
  @Input() data: any = []
  @Input() titleChart: any
  @Input() categories: any
  @Input() color: any
  @Input() isPercent: boolean = false
  @Input() min: number = 0
  @Input() max: number = 200
  @Input() stepSize?: number = 10
  @Input() name?: any
  @Input() average: any

  readonly formatPrice = formatPrice
  readonly formatNoDecimal = formatNoDecimal

  chartOptions: any
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.setData()
    }, 1000)
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.setData()
    }, 1000)
  }

  setData() {
    this.chartOptions = {
      series: this.data || null,
      chart: {
        redrawOnWindowResize: false,
        height: 600,
        type: 'bar',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
            orientation: 'vertical',
            hideOverflowingLabels: false,
            rangeBarGroupRows: true,
          },
          // columnWidth: this.data?.length > 1 ? '50%' : '33%',
          columnWidth: '20px',
          barHeight: '100px',
        },
      },
      xaxis: {
        categories: this.categories,
        labels: {
          style: {
            colors: ['#525252'],
            fontSize: '12px',
            fontWeight: '400',
          },
          // rotate: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return formatPrice(val)
          },
          style: {
            colors: ['#404040'],
            fontSize: '12px',
            fontWeight: '400',
          },
        },
        // stepSize: this.stepSize,
        min: this.min,
        max: this.max,
      },
      dataLabels: {
        enabled: true,
        offsetY: 2,
        style: {
          colors: ['#737373'],
          fontSize: '12px',
          fontWeight: '400',
        },
        formatter: function (val: any) {
          return val == 0 ? '' : formatPrice(val)
        },
      },
      title: {
        text: this.titleChart,
        style: {
          fontSize: '14px',
          fontWeight: '400',
          color: '#262626',
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        colors: this.color,
      },
      legend: {
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Kanit',
        fontWeight: 400,
        labels: {
          colors: '#525252',
        },
        markers: {
          size: 6,
          shape: 'circle',
          strokeWidth: 2,
          fillColors: this.color,
          radius: 2,
          offsetX: -8,
        },
        itemMargin: {
          horizontal: 30,
        },
      },
      stroke: {
        colors: ['transparent'],
        width: 4,
      },
    }
  }
}
