<div>
  <mat-form-field
    appearance="outline"
    class="input {{ remove_bottom && 'remove-bottom'}}"
    hintLabel="{{hintLabel}}">
    @if (value !== undefined) {
    <input matInput [value]="value" placeholder="{{placeholder || ''}}" [disabled]="true" />
    } @else {
    <input
      matInput
      [formControl]="formControl"
      placeholder="{{placeholder || ''}}"
      (input)="onInput.emit($event)"
      (change)="onChange.emit($event)"
      (keydown.enter)="onEnter.emit($event)"
      maxlength="{{ maxlength }}"
      minlength="{{ minlength }}"
      max="{{ max }}"
      min="{{ min }}"
      [type]="type" />
    } @if (control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    } @if (unit) {
    <div matSuffix class="unit">{{unit}}</div>
    }
  </mat-form-field>
</div>
