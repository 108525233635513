<div class="tabs-content-wrapper-detail">
  <div class="d-flex justify-content-between">
    <!--  (onEnter)="getProductBrandList()" -->
    <app-search
      [control]="search"
      placeholder="ค้นหา"
      (onEnter)="paginationCredit.reset();getCustomerCreditList()"></app-search>

    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.VIEW])) {
    <button
      matRipple
      class="btn btn-outline-secondary min-w-100px"
      (click)="openModalDownloadFile('ดาวน์โหลดไฟล์การใช้เครดิต', { message: 'คุณต้องการดาวน์โหลดไฟล์การใช้เครดิตใช่หรือไม่' })">
      <span class="icon material-symbols-outlined fill">download_2</span>
      ส่งออกไฟล์
    </button>
    }
  </div>

  <div class="table-responsive-detail mt-3">
    <div [style]="{ 'min-width': '1000px' }">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            <th width="260">เครดิตที่เปลี่ยน</th>
            <th>หมายเหตุ</th>
            <th width="200">โดย</th>
            <th width="200">วันที่</th>
          </tr>
        </thead>

        <tbody>
          @for (item of creditList; track $index) {
          <tr>
            <td>
              {{ item.credit_type_name }}
              <span [class]="item.credit_type == 2 ? 'color--red-600' : 'color--green-600'">
                {{ formatPrice(item.credit) }}
              </span>
            </td>
            <td>{{ item.remark }}</td>
            <td>{{ item.updated_by ? item.updated_by.full_name : '' }}</td>
            <td>{{ Moment(item.updated_at).format('DD-MM-YYYY, HH:mm') }}</td>
          </tr>
          }
        </tbody>
      </table>
    </div>

    @if(!creditList.length) {
    <div class="data-not-found-wrapper">ไม่พบรายการเครดิต</div>
    }
  </div>

  <!-- Pagination -->
  @if (creditList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationCredit.data.pageIndex"
      [length]="paginationCredit.data.length"
      [pageSize]="paginationCredit.data.pageSize"
      [pageSizeOptions]="paginationCredit.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationCredit.setFromPaginator($event);getCustomerCreditList()">
    </mat-paginator>
  </div>
  }
</div>
