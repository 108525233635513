import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, ORDER_ID, convertDateToApi } from '../../helpers'
import {
  MasterService,
  ProductBrandService,
  ProductCategoryService,
  TransportService,
} from '../../services'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { forkJoin } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class FilterReportProduct extends __searchAndFitler {
  readonly list: any = {
    category: <any[]>[],
    brand: <any[]>[],
    period: [
      { id: 'all', name: 'ทั้งหมด' },
      { id: 'year', name: 'ปีนี้' },
      { id: 'month', name: 'เดือนนี้' },
      { id: 'week', name: 'อาทิตย์นี้' },
      { id: 'day', name: 'วันนี้' },
    ],
  }

  form = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    category: new FormControl(''),
    brand: new FormControl(''),
    period: new FormControl(''),
    status_closed: new FormControl(''),
  })

  get isFilter() {
    const data = this.getData(true)
    return data.filter && Object.keys(data.filter).length
  }

  get startEndDateFormat() {
    return [this.form.controls['start_date'].value, this.form.controls['end_date'].value]
      .reduce((ds: any[], d: any) => {
        if (d) {
          ds.push(Moment(d).format('DD/MM/YYYY'))
        }
        return ds
      }, [])
      .join(' - ')
  }

  constructor(
    public masterService: MasterService,
    public productCategoryService: ProductCategoryService,
    public productBrandService: ProductBrandService,
    public transportService: TransportService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {
    const filter = {
      category_ids: [],
      brand_ids: [],
    }
    super('/report/product', filter)

    this._initForm(this.form)
  }

  async initData() {
    let payload = {
      sort_name: 'name',
      sort_by: 'asc',
    }
    const apis = [
      this.productCategoryService.getProductCategoryList(payload),
      this.productBrandService.getProductBrandList(payload),
    ]

    await new Promise(rev => {
      this.loading.start()
      forkJoin(apis).subscribe(([resCategory, resBrand]: any) => {
        if (resCategory) {
          if (!resCategory.is_error) {
            this.list.category = resCategory.data
          } else {
            this.customSnackBar.fail(resCategory.message)
          }
        }

        if (resBrand) {
          if (!resBrand.is_error) {
            this.list.brand = resBrand.data
          } else {
            this.customSnackBar.fail(resBrand.message)
          }
        }

        rev(null)

        this.loading.stop()
      })
    })
  }

  isGetName(id: any, list: string) {
    return this.list[`${list}`].find((val: any) => val.id === id)
  }

  removeStartEndDate() {
    this.form.controls['start_date'].reset()
    this.form.controls['end_date'].reset()
  }

  resetCategory() {
    this.form.controls['category'].reset()
  }

  resetBrand() {
    this.form.controls['brand'].reset()
  }

  resetPeriod() {
    this.form.controls['period'].reset()
  }

  getData(isAll = false) {
    const filter: any = {}

    const data: any = {
      filter,
    }

    const value = this.form.getRawValue()

    if (value.category) {
      filter.category_ids = [value.category]
    }

    if (value.brand) {
      filter.brand_ids = [value.brand]
    }

    if (value.period && (value.period != 'all' || isAll)) {
      console.log('ssss', value.period, isAll)

      filter.period = value.period
    }

    if (value.start_date) {
      filter.start_date = convertDateToApi(value.start_date)
    }

    if (value.end_date) {
      filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    if (value.status_closed) {
      filter.status_ids = [ORDER_ID.SHIPMENT]
    }

    data.filter = filter

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
