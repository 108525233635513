import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { StatusUserComponent } from '../../../components/status-user/status-user'
import { SearchFilterComponent } from './search-filter/search-filter'
import { MatPaginatorModule } from '@angular/material/paginator'
import { ModalPromotionComponent } from './modal-promotion/modal-promotion'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import { imports } from '../../../imports'
import {
  DownloadFileBlob,
  Moment,
  Pagination,
  formatPrice,
  formatPriceAutoDecimal,
} from '../../../helpers'
import {
  ACTION_ACCESS,
  APP_PERMISSION,
  FilterTablePromotion,
  Loading,
  Profile,
} from '../../../globals'
import { PromotionService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ModalProductComponent } from './modal-product/modal-product'
import { TextTooltipComponent } from '../../../components/text-tooltip/text-tooltip'

@Component({
  selector: 'app-promotion',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    CheckboxTableComponent,
    CheckboxAllTableComponent,
    StatusUserComponent,
    SearchFilterComponent,
    MatPaginatorModule,
    ModalPromotionComponent,
    ModalDownloadFileComponent,
    MatTooltipModule,
    TextTooltipComponent,
  ],
  templateUrl: './promotion.html',
  styleUrls: ['./promotion.scss'],
})
export class PromotionComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  readonly paginationPromotion = new Pagination({
    sortName: 'code',
  })
  promotionList: any[] = []
  itemChecked = new Set<any>()

  now = Moment().unix()

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public promotionService: PromotionService,
    public filterTablePromotion: FilterTablePromotion
  ) {}

  ngOnInit(): void {
    this.getPromotionList()
  }

  getPromotionList() {
    const now = Moment().unix()
    const pagination = this.paginationPromotion.get()
    const dataFilter = this.filterTablePromotion.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.promotionService.getPromotionList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.promotionList = res.data.records.map((d: any) => {
            return {
              ...d,
              tierNames: (d.tier_names || []).split(',').join(', '),
              isExpired: Moment(d.effective_date_to).unix() < now,
            }
          })
          this.paginationPromotion.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  tierName(item: any) {
    return (item.tiers || []).map((d: any) => d.tier.name).join(', ')
  }

  productLength(item: any) {
    return (item.products || []).length
  }

  openModalProduct(promotion: any) {
    this.dialog.open(ModalProductComponent, {
      width: '1040px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        promotion,
      },
    })
  }

  openModalPromotion(title: string, type: 'add' | 'edit', dataManage?: any) {
    const dialogRef = this.dialog.open(ModalPromotionComponent, {
      disableClose: true,
      data: {
        title,
        type,
        dataManage,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPromotionList()
      }
    })

    return dialogRef
  }

  openModalDownloadFile(title: string, detail: any): void {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })
  }

  downloadFile() {
    const pagination = this.paginationPromotion.get()
    const dataFilter = this.filterTablePromotion.getData()
    dataFilter.filter.ids = this.itemChecked.size > 0 ? [...this.itemChecked.values()] : undefined

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }

    this.loading.start()
    this.promotionService.exportPromotion(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `promotions.xlsx`)
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
