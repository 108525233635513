import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialog,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { InputComponent } from '../../../../components/input/input'
import { SelectComponent } from '../../../../components/select/select'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import { imports } from '../../../../imports'
import { ValidateForm, setErrorForm } from '../../../../helpers'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { ModalDeleteComponent } from '../../../../components/modal-delete/modal-delete'

@Component({
  selector: 'app-modal-address',
  templateUrl: './modal-address.html',
  styleUrls: ['./modal-address.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    MatCheckbox,
    ModalConfirmComponent,
  ],
})
export class ModalAddressComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar
  ) {}

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    telephone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9\-]+$/),
      Validators.maxLength(100),
    ]),
    address: new FormControl('', [Validators.required]),
    is_main: new FormControl(true),
  })

  ngOnInit(): void {
    const is_main = this.data.item.is_main
    this.form.reset({
      name: this.data.item.name || '',
      telephone: this.data.item.telephone || '',
      address: this.data.item.address || '',
      is_main,
    })
    if (is_main) {
      this.form.controls.is_main.disable()
    }
  }

  onDelete() {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบที่อยู่จัดส่งนี้ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close('delete')
      }
    })
  }

  onClose(): void {
    if (this.data.type == 'edit' && this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const value = this.form.getRawValue()
    this.dialogRef.close({
      ...value,
      id: this.data.item.id || null,
      uuid: this.data.item.uuid || crypto.randomUUID(),
    })
  }
}
