<div class="tabs-content-wrapper">
  <div class="top-content-wrapper">
    <div class="top-content-left mb-3">
      <app-search
        [control]="filterTableHistoryOrderInvoice.search"
        placeholder="ค้นหา"
        (onEnter)="paginationHistory.reset();getList();itemChecked.clear()"></app-search>
    </div>
    <div class="top-content-right mb-3">
      <label class="label"> วันที่พิมพ์ </label>
      <app-date-picker-range
        [controlStr]="form.controls['start_printed_date']"
        [controlEnd]="form.controls['end_printed_date']"
        [hideError]="true"
        (onChange)="paginationHistory.reset();getList();itemChecked.clear()"></app-date-picker-range>

      @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        class="btn btn-outline-secondary"
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์ใบกำกับภาษี', { message: 'คุณต้องการดาวน์โหลดไฟล์ใบกำกับภาษีที่เลือกใช่หรือไม่' })">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive table-main-tabs-wrapper">
    <table class="table table-fixed-column">
      <thead>
        <tr class="tr-vertical-top">
          @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
          <th width="70" class="colum-checkbox">
            <app-checkbox-all-table
              [checkedValue]="itemChecked"
              [datas]="historyList"
              fieldValue="id"></app-checkbox-all-table>
          </th>
          }
          <th width="160">
            <app-sort-by key="invoice_no" [pagination]="paginationHistory" (onChange)="getList()">
              เลขที่ใบกำกับภาษี
            </app-sort-by>
          </th>
          <th width="160">
            <app-sort-by key="order_no" [pagination]="paginationHistory" (onChange)="getList()">
              เลขที่ออเดอร์
            </app-sort-by>
          </th>
          <th width="230">
            <app-sort-by key="tax_name" [pagination]="paginationHistory" (onChange)="getList()">
              ชื่อ-นามสกุล / บริษัทผู้เสียภาษี
            </app-sort-by>
          </th>
          <th width="250">ที่อยู่ออกใบกำกับภาษี</th>
          <th width="160">เลขผู้เสียภาษี</th>
          <th width="160">เบอร์โทรศัพท์</th>
          <th width="160">อีเมลลูกค้า</th>
          <th width="200">ผู้ออก</th>
          <th width="250">ที่อยู่ผู้ออก</th>
          <th width="160">เลขผู้เสียภาษี</th>
          <th width="200">หมายเหตุ</th>
          <th width="180">จัดเตรียมโดย</th>
          <th width="180">รหัสสินค้า</th>
          <th width="250">ชื่อสินค้า</th>
          <th width="100" class="text-center">จำนวน</th>
          <th width="160" class="text-end">ราคาต่อหน่วย (บาท)</th>
          <th width="160" class="text-end">จำนวนเงิน (รวมภาษี)</th>
          <th width="160" class="text-end">ส่วนลดรวม (บาท)</th>
          <th width="160" class="text-end">ค่าส่ง</th>
          <th width="220" class="text-end">ยอดขายสุทธิก่อนภาษีมูลค่าเพิ่ม</th>
          <th width="160" class="text-end">ภาษีมูลค่าเพิ่ม 7%</th>
          <th width="160" class="text-end">ราคาสุทธิ (บาท)</th>
          <th width="180">
            <app-sort-by key="printed_date" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่พิมพ์ใบกำกับภาษี
            </app-sort-by>
          </th>
          <th width="160">พิมพ์โดย</th>
          <th width="110" class="text-center">ใบกำกับภาษี</th>
        </tr>
      </thead>

      <tbody>
        @for (item of historyList; track item) {
        <tr class="tr-vertical-top">
          @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
          <td class="colum-checkbox">
            <app-checkbox-table [checkedValue]="itemChecked" [value]="item.id"></app-checkbox-table>
          </td>
          }
          <td>{{ item.invoice_no || '-' }}</td>
          <td>{{ item.order_no || '-' }}</td>
          <td>{{ item.tax_name || '-' }}</td>
          <td>
            <app-text-tooltip>{{ item.tax_address || '-' }}</app-text-tooltip>
          </td>
          <td>{{ item.tax_id || '-' }}</td>
          <td>{{ item.tax_telephone || '-' }}</td>
          <td>{{ item.email || '-' }}</td>
          <td>{{ item.company_name || '-' }}</td>
          <td>
            <app-text-tooltip>{{ item.company_address || '-' }}</app-text-tooltip>
          </td>
          <td>{{ item.company_tax_id || '-' }}</td>
          <td>
            <app-text-tooltip>{{ item.remark || '-' }}</app-text-tooltip>
          </td>
          <td>{{ item.updated_by?.full_name || '-' }}</td>
          <td class="px-0 py-3" colspan="5">
            <table class="w-100">
              <colgroup>
                <col width="180" />
                <col width="250" />
                <col width="100" />
                <col width="160" />
                <col width="160" />
              </colgroup>

              <tbody>
                @for (product of item.products; track $index) {
                <tr class="tr-sub-product">
                  <td>{{ product.item.code || '-' }}</td>
                  <td>{{ product.item.name || '-' }}</td>
                  <td class="text-center">{{ formatPriceAutoDecimal(product.quantity) }}</td>
                  <td class="text-end">{{ formatPrice(product.price) }}</td>
                  <td class="text-end">{{ formatPrice(product.total_price) }}</td>
                </tr>
                }
              </tbody>
            </table>
          </td>
          <td class="text-end">{{ formatPrice(item.discount) }}</td>
          <td class="text-end">{{ formatPrice(item.shipping_cost) }}</td>
          <td class="text-end">{{ formatPrice(item.before_vat) }}</td>
          <td class="text-end">{{ formatPrice(item.vat) }}</td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          <td>
            {{ item.printed_date ? Moment(item.printed_date).format('DD-MM-YYYY HH:mm') : '-' }}
          </td>
          <td>{{ item.printed_by?.full_name || '-' }}</td>
          <td>
            <div class="d-flex justify-content-center">
              <button
                matRipple
                matTooltip="ดู"
                matTooltipPosition="above"
                class="btn btn-outline-secondary btn-icon"
                (click)="toPrint('tax', [item.id])">
                <span class="icon material-symbols-outlined fill">description</span>
              </button>
            </div>
          </td>
        </tr>
        }
      </tbody>
      @if (historyList.length) {
      <tfoot class="tfoot-fixed">
        <tr class="tr-sum-total">
          <td
            [attr.colspan]="profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW]) ? 2 : 1">
            รวม
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-end">{{ formatPrice(sumField('products.total_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('discount')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('shipping_cost')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('before_vat')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('vat')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('total_price')) }}</td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
        </tr>
      </tfoot>
      }
    </table>

    @if(!historyList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูลใบกำกับภาษี</div>
    }
  </div>

  <!-- Pagination -->
  @if (historyList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationHistory.data.pageIndex"
      [length]="paginationHistory.data.length"
      [pageSize]="paginationHistory.data.pageSize"
      [pageSizeOptions]="paginationHistory.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationHistory.setFromPaginator($event);getList()">
    </mat-paginator>
  </div>
  }
</div>
