import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { FormControl, FormGroup } from '@angular/forms'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTooltipModule } from '@angular/material/tooltip'
import {
  ACTION_ACCESS,
  APP_PERMISSION,
  FilterTableSupplier,
  Loading,
  Profile,
} from '../../../../globals'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { SearchComponent } from '../../../../components/search/search'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { SupplierService } from '../../../../services'
import { Moment, Pagination } from '../../../../helpers'
import { imports } from '../../../../imports'
import { TextTooltipComponent } from '../../../../components/text-tooltip/text-tooltip'
@Component({
  selector: 'app-tab-supplier',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    ModalLeaveComponent,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    TextTooltipComponent,
  ],
  templateUrl: './tab-supplier.html',
  styleUrls: ['./tab-supplier.scss'],
})
export class TabSupplierComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  readonly paginationSupplier = new Pagination({
    sortName: 'code',
  })
  supplierList: any[] = []

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public supplierService: SupplierService,
    public filterTableSupplier: FilterTableSupplier
  ) {}

  ngOnInit(): void {
    this.getSupplierList()
  }

  getSupplierList() {
    const pagination = this.paginationSupplier.get()
    const dataFilter = this.filterTableSupplier.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.supplierService.getSupplierList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.supplierList = res.data.records
          this.paginationSupplier.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }
}
