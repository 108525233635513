<div class="tabs-wrapper">
  <mat-tab-group
    animationDuration="0ms"
    [disableRipple]="true"
    [selectedIndex]="tab"
    (selectedIndexChange)="changeTab($event)">
    <mat-tab label="ข้อมูลทั่วไป">
      <div class="tabs-content-wrapper">
        <div class="top-content-wrapper">
          <div class="top-content-left">
            <app-search-filter-expense-item
              placeholder="ค้นหา"
              (onConfirm)="paginationExpenseItem.reset();getExpenseItemList();itemChecked1.clear()"></app-search-filter-expense-item>
          </div>
          <div class="top-content-right mb-3">
            <div class="date-range-wrapper">
              <label class="label"> วันที่ทำรายการ </label>

              <app-date-picker-range
                [controlStr]="filterTableHistoryExpense.form.controls['start_date']"
                [controlEnd]="filterTableHistoryExpense.form.controls['end_date']"
                (onChange)="paginationExpenseItem.reset();getExpenseItemList();itemChecked1.clear()"></app-date-picker-range>
            </div>

            @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
            <button
              matRipple
              class="btn btn-outline-secondary"
              (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายการค่าใช้จ่าย', { message: 'คุณต้องการดาวน์โหลดไฟล์รายการค่าใช้จ่ายที่เลือกใช่หรือไม่' })">
              <span class="icon material-symbols-outlined fill">download_2</span>
              ส่งออกไฟล์
            </button>
            }
          </div>
        </div>

        <div class="table-responsive table-main-tabs-wrapper">
          <table class="table table-fixed-column">
            <thead>
              <tr class="tr-vertical-top">
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <th width="70" class="colum-checkbox">
                  <app-checkbox-all-table
                    [checkedValue]="itemChecked1"
                    [datas]="expenseItemList"
                    fieldValue="id"></app-checkbox-all-table>
                </th>
                }
                <th width="160">รหัสค่าใช้จ่าย</th>
                <th width="200">ชื่อค่าใช้จ่าย</th>
                <th width="160">หมวดหมู่</th>
                <th width="160" class="text-end">ค่าใช้จ่าย</th>
                <th width="160">
                  <app-sort-by
                    key="date"
                    [pagination]="paginationExpenseItem"
                    (onChange)="getExpenseItemList()">
                    วันที่จ่าย
                  </app-sort-by>
                </th>
                <th width="200">ไฟล์แนบ</th>
                <th width="200">หมายเหตุ</th>
                <th width="160">ทำรายการโดย</th>
                <th width="160">
                  <app-sort-by
                    key="created_at"
                    [pagination]="paginationExpenseItem"
                    (onChange)="getExpenseItemList()">
                    วันที่ทำรายการ
                  </app-sort-by>
                </th>
              </tr>
            </thead>

            <tbody>
              @for (item of expenseItemList; track item) {
              <tr class="tr-vertical-top">
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <td class="colum-checkbox">
                  <app-checkbox-table
                    [checkedValue]="itemChecked1"
                    [value]="item.id"></app-checkbox-table>
                </td>
                }
                <td>{{ item.details && item.details[0]?.code || '-' }}</td>
                <td>{{ item.details && item.details[0]?.name || '-' }}</td>
                <td>{{ item.expense_category.name || '-' }}</td>
                <td class="text-end">{{ formatPrice(item.details && item.details[0]?.price) }}</td>
                <td>{{ item.date ? Moment(item.date).format('DD-MM-YYYY') : '-' }}</td>
                <td>
                  <div class="d-flex flex-column">
                    @for (f of item.files; track $index) {
                    <div class="d-flex gap-2">
                      <app-text-tooltip> {{ f.file.name || '-' }} </app-text-tooltip>
                      <div
                        class="color--brand-blue cursor-pointer"
                        (click)="downloadFiles(f.file)"
                        [style]="{ 'min-width': '64px' }">
                        ดาวน์โหลด
                      </div>
                    </div>
                    } @empty { - }
                  </div>
                </td>
                <td>{{ item.remark || '-' }}</td>
                <td>{{ item.updated_by.full_name || '-' }}</td>
                <td>
                  {{ item.created_at ? Moment(item.created_at).format('DD-MM-YYYY HH:mm') : '-' }}
                </td>
              </tr>
              }
            </tbody>
            @if (expenseItemList.length) {
            <tfoot class="tfoot-fixed">
              <tr class="tr-sum-total">
                <td
                  [attr.colspan]="profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW]) ? 2 : 1">
                  รวม
                </td>
                <td></td>
                <td></td>
                <td class="text-end">{{ formatPrice(sumFieldEexpenseItem('details.price')) }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
            }
          </table>

          @if(!expenseItemList.length) {
          <div class="data-not-found-wrapper">ไม่พบข้อมูลรายการค่าใช้จ่าย</div>
          }
        </div>

        <!-- Pagination -->
        @if (expenseItemList.length) {
        <div class="pagination-wrapper">
          <mat-paginator
            [pageIndex]="paginationExpenseItem.data.pageIndex"
            [length]="paginationExpenseItem.data.length"
            [pageSize]="paginationExpenseItem.data.pageSize"
            [pageSizeOptions]="paginationExpenseItem.data.pageSizeOptions"
            aria-label="Select page"
            (page)="paginationExpenseItem.setFromPaginator($event);getExpenseItemList()">
          </mat-paginator>
        </div>
        }
      </div>
    </mat-tab>

    <mat-tab label="รายงานค่าใช้จ่ายจากสินค้าชำรุด">
      <div class="tabs-content-wrapper">
        <div class="top-content-wrapper">
          <div class="top-content-left mb-3">
            <app-search
              [control]="formExpenseDefective.controls['search']"
              placeholder="ค้นหา"
              (onEnter)="paginationExpenseDefective.reset();getExpenseDefectiveList();itemChecked2.clear()"></app-search>
          </div>
          <div class="top-content-right mb-3">
            <div class="date-range-wrapper">
              <label class="label"> วันที่ทำรายการ </label>

              <app-date-picker-range
                [controlStr]="formExpenseDefective.controls['start_date']"
                [controlEnd]="formExpenseDefective.controls['end_date']"
                (onChange)="paginationExpenseDefective.reset();getExpenseDefectiveList();itemChecked2.clear()"></app-date-picker-range>
            </div>

            @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
            <button
              matRipple
              class="btn btn-outline-secondary"
              (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายการค่าใช้จ่ายจากสินค้าชำรุด', { message: 'คุณต้องการดาวน์โหลดไฟล์รายการค่าใช้จ่ายจากสินค้าชำรุดที่เลือกใช่หรือไม่' })">
              <span class="icon material-symbols-outlined fill">download_2</span>
              ส่งออกไฟล์
            </button>
            }
          </div>
        </div>

        <div class="table-responsive table-main-tabs-wrapper">
          <table class="table table-fixed-column">
            <thead>
              <tr class="tr-vertical-top">
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <th width="70" class="colum-checkbox">
                  <app-checkbox-all-table
                    [checkedValue]="itemChecked2"
                    [datas]="expenseDefectiveList"
                    fieldValue="id"></app-checkbox-all-table>
                </th>
                }
                <th width="200">รหัสสินค้า</th>
                <th width="200">ชื่อสินค้า</th>
                <th width="160" class="text-end">ราคา</th>
                <th width="160" class="text-center">จำนวนที่ชำรุด</th>
                <th width="160">วันที่</th>
                <th width="200">หมายเหตุ</th>
                <th width="160">ทำรายการโดย</th>
                <th width="160">
                  <app-sort-by
                    key="created_at"
                    [pagination]="paginationExpenseDefective"
                    (onChange)="getExpenseDefectiveList()">
                    วันที่ทำรายการ
                  </app-sort-by>
                </th>
              </tr>
            </thead>

            <tbody>
              @for (item of expenseDefectiveList; track item) {
              <tr class="tr-vertical-top">
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <td class="colum-checkbox">
                  <app-checkbox-table
                    [checkedValue]="itemChecked2"
                    [value]="item.id"></app-checkbox-table>
                </td>
                }
                <td>
                  @for (detail of item.details; track $index) {
                  <div>{{detail.code || '-'}}</div>
                  } @empty {
                  <div>-</div>
                  }
                </td>
                <td>
                  @for (detail of item.details; track $index) {
                  <div>{{detail.product.name || '-'}}</div>
                  } @empty {
                  <div>-</div>
                  }
                </td>
                <td class="text-end">
                  @for (detail of item.details; track $index) {
                  <div>{{formatPrice(detail.price)}}</div>
                  } @empty {
                  <div>-</div>
                  }
                </td>
                <td class="text-center">
                  @for (detail of item.details; track $index) {
                  <div>{{formatNoDecimal(detail.qty)}}</div>
                  } @empty {
                  <div>-</div>
                  }
                </td>
                <td>{{ item.date ? Moment(item.date).format('DD-MM-YYYY') : '-' }}</td>
                <td>{{ item.remark || '-' }}</td>
                <td>{{ item.updated_by.full_name || '-' }}</td>
                <td>
                  {{ item.created_at ? Moment(item.created_at).format('DD-MM-YYYY HH:mm') : '-' }}
                </td>
              </tr>
              }
            </tbody>
            @if (expenseDefectiveList.length) {
            <tfoot class="tfoot-fixed">
              <tr class="tr-sum-total">
                <td
                  [attr.colspan]="profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW]) ? 2 : 1">
                  รวม
                </td>
                <td></td>
                <td class="text-end">
                  {{ formatPrice(sumFieldEexpenseDefective('details.price')) }}
                </td>
                <td class="text-center">
                  {{ formatNoDecimal(sumFieldEexpenseDefective('details.qty')) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
            }
          </table>

          @if(!expenseDefectiveList.length) {
          <div class="data-not-found-wrapper">ไม่พบข้อมูลรายงานค่าใช้จ่ายจากสินค้าชำรุด</div>
          }
        </div>

        <!-- Pagination -->
        @if (expenseDefectiveList.length) {
        <div class="pagination-wrapper">
          <mat-paginator
            [pageIndex]="paginationExpenseDefective.data.pageIndex"
            [length]="paginationExpenseDefective.data.length"
            [pageSize]="paginationExpenseDefective.data.pageSize"
            [pageSizeOptions]="paginationExpenseDefective.data.pageSizeOptions"
            aria-label="Select page"
            (page)="paginationExpenseDefective.setFromPaginator($event);getExpenseItemList()">
          </mat-paginator>
        </div>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
