import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTooltipModule } from '@angular/material/tooltip'
import {
  Loading,
  Profile,
  FilterTableProduct,
  FilterTableHistoryOrderReturn,
  ACTION_ACCESS,
  APP_PERMISSION,
} from '../../../globals'
import { SearchComponent } from '../../../components/search/search'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { imports } from '../../../imports'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import {
  Pagination,
  DownloadFileBlob,
  formatPrice,
  formatPriceAutoDecimal,
  Moment,
  getSessionStorage,
  setSessionStorage,
  convertDateToApi,
} from '../../../helpers'
import { ProductStockService } from '../../../services'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { FormControl, FormGroup } from '@angular/forms'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { SortByComponent } from '../../../components/sort-by/sort-by'
import { HistoryService } from '../../../services/history.service'
import { TextTooltipComponent } from '../../../components/text-tooltip/text-tooltip'

@Component({
  selector: 'app-product-return',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    DatePickerRangeComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    SortByComponent,
    TextTooltipComponent,
  ],
  templateUrl: './product-return.html',
  styleUrls: ['./product-return.scss'],
})
export class ProductReturnComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment

  tab = 0

  readonly paginationHistory = new Pagination({
    sortBy: 'desc',
  })
  historyList: any = []
  itemChecked = new Set<any>()

  readonly key_storage_id = 'history/order-return'
  form = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableHistoryOrderReturn: FilterTableHistoryOrderReturn,
    public historyService: HistoryService
  ) {}
  ngOnInit(): void {
    const values = getSessionStorage(this.key_storage_id)
    if (values) {
      try {
        this.form.setValue(values)
      } catch (error) {}
    }

    this.form.valueChanges.subscribe(vals => {
      setSessionStorage(this.key_storage_id, vals)
    })

    this.getList()
  }

  getFilter() {
    const dataFilter = this.filterTableHistoryOrderReturn.getData()

    const value = this.form.getRawValue()
    if (value.start_date) {
      dataFilter.filter.start_date = convertDateToApi(value.start_date)
    }
    if (value.end_date) {
      dataFilter.filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    return dataFilter
  }

  getList(): void {
    const pagination = this.paginationHistory.get()
    const dataFilter = this.getFilter()

    const payload = {
      ...pagination,
      ...dataFilter,
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.historyService.getHistoryOrderReturnList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.historyList = res.data.records

          this.paginationHistory.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)
  }

  navigateToTab(tab: number) {
    this.Router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const pagination = this.paginationHistory.get()
    const dataFilter = this.getFilter()
    dataFilter.filter.ids = this.itemChecked.size > 0 ? [...this.itemChecked.values()] : undefined

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }
    this.loading.start()
    this.historyService.exportHistoryOrderReturnList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `history-order-return.xlsx`)
          this.itemChecked.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  sumField(field: string) {
    return this.historyList.reduce((total: number, item: any) => {
      return total + (item[field] || 0)
    }, 0)
  }
}
