import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, ORDER_ID, convertDateToApi } from '../../helpers'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { PaymentChannelService } from '../../services'
import { HistoryService } from '../../services/history.service'

@Injectable({ providedIn: 'root' })
export class FilterTableHistoryPayment extends __searchAndFitler {
  readonly list = {
    payment_types: <any[]>[
      { id: 1, text: 'โอนเงินผ่านธนาคาร' },
      { id: 4, text: 'QR Payment' },
      { id: 3, text: 'Credit / Debit Card' },
      { id: 2, text: 'Cash' },
    ],
    is_successes: <any[]>[
      { id: true, text: 'สำเร็จ' },
      { id: false, text: 'ไม่สำเร็จ' },
    ],
    bank_ids: <any[]>[],
  }

  form = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  constructor(
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public historyService: HistoryService
  ) {
    const filter = {
      payment_types: [],
      bank_ids: [],
      is_successes: [],
    }
    super('/history/payment', filter)

    this._initForm(this.form)
  }

  initData() {
    this.getHistoryPaymentFilter()
  }

  getHistoryPaymentFilter() {
    this.loading.start()
    this.historyService.getHistoryPaymentFilter().subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.list.bank_ids = res.data.map((item: any) => {
            return { ...item, text: item.name }
          })
        }
      }
      this.loading.stop()
    })
  }

  isChecked(id: any, key: string) {
    return this.filter[key].some((val: any) => val === id)
  }

  change(id: any, key: string) {
    const filter = this.filter[key]
    if (filter.includes(id)) {
      filter.splice(filter.indexOf(id), 1)
    } else {
      filter.push(id)
    }
  }

  remove(id: any, key: string) {
    this.change(id, key)
  }

  isSuccessesChecked(id: any) {
    return this.filter['is_successes'].some((val: any) => val === id)
  }

  successesChange(id: any) {
    const is_successes = this.filter['is_successes']
    if (is_successes.includes(id)) {
      is_successes.splice(is_successes.indexOf(id), 1)
    } else {
      is_successes.push(id)
    }
  }

  removeSuccesses(id: any) {
    this.successesChange(id)
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const payment_types = this.filter['payment_types']
    if (payment_types.length) {
      filter.payment_types = payment_types
    }

    const bank_ids = this.filter['bank_ids']
    if (bank_ids.length) {
      filter.bank_ids = bank_ids
    }

    const is_successes = this.filter['is_successes']
    if (is_successes.length) {
      filter.is_successes = is_successes
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
