import { Component } from '@angular/core'
import { imports } from '../../imports'
import { MatDialog } from '@angular/material/dialog'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { SelectComponent } from '../../components/select/select'
import { SearchComponent } from '../../components/search/search'
import { ACTION_ACCESS, APP_PERMISSION, FilterTableOrder, Loading, Profile } from '../../globals'
import { ModalDownloadFileComponent } from '../../components/modal-download-file/modal-download-file'
import { ModalUploadFileComponent } from './modal-upload-file/modal-upload-file'
import { OrderLogComponent } from './order-log/order-log'
import { SearchFilterComponent } from './search-filter/search-filter'
import { MatPaginatorModule } from '@angular/material/paginator'
import { ModalMergeOrderComponent } from './modal-merge-order/modal-merge-order'
import {
  DownloadFileBlob,
  EnumPrintTypeValue,
  KEY_STARAGE_LIST_UPDATE,
  Moment,
  ORDER_ID,
  Pagination,
  getSessionStorage,
  isActionPrint,
  setSessionStorage,
} from '../../helpers'
import { Router } from '@angular/router'
import { MasterService } from '../../services'
import { OrderService } from '../../services/order.service'
import { MatMenuModule } from '@angular/material/menu'
import { ModalErrorComponent } from '../../components/modal-error/modal-error'
import { filter, forkJoin } from 'rxjs'
import { decrypt, encrypt } from '../../helpers/crypto'

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    ...imports,
    SearchComponent,
    SelectComponent,
    OrderLogComponent,
    SearchFilterComponent,
    MatPaginatorModule,
    MatMenuModule,
  ],
  templateUrl: './order.html',
  styleUrls: ['./order.scss'],
})
export class OrderComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly ORDER_ID = ORDER_ID

  orderSelect = new Map<any, any>()

  readonly key_storage_status_id = 'order/filter/status_id'
  readonly status_id = new FormControl(getSessionStorage(this.key_storage_status_id) || 'all')

  readonly paginationOrder = new Pagination({
    sortBy: 'desc',
  })
  orderList: any[] = []

  consts: any[] = [
    { status_id: ORDER_ID.CREATE, description: '', count: 0 },
    { status_id: ORDER_ID.VERIFY, description: '', count: 0 },
    { status_id: ORDER_ID.PAID, description: '', count: 0 },
    { status_id: ORDER_ID.PRINTED, description: '', count: 0 },
  ]
  list = {
    status: <any[]>[
      { id: 'all', name: 'ทั้งหมดที่ยังไม่ปิดรายการ' },
      { id: ORDER_ID.CREATE, name: 'รอชำระเงิน' },
      { id: ORDER_ID.VERIFY, name: 'ตรวจหลักฐานการชำระเงิน' },
      { id: ORDER_ID.PAID, name: 'รอพิมพ์ที่อยู่ (ชำระเงินแล้ว)' },
      { id: ORDER_ID.PRINTED, name: 'รอเลขพัสดุ (พิมพ์แล้ว)' },
      { id: ORDER_ID.SHIPMENT, name: 'ส่งสินค้าแล้ว (ปิดรายการแล้ว) ' },
    ],
  }

  get totalOrder() {
    return this.consts.reduce((total: number, d: any) => total + d.count, 0)
  }

  get orderForPrint() {
    return [...this.orderSelect.entries()].filter(([_, v]) => v.isActionPrint)
  }

  get isPrintTax() {
    return this.orderForPrint.some(([_, v]) => v.is_tax)
  }

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableOrder: FilterTableOrder,
    public masterService: MasterService,
    public orderService: OrderService
  ) {}

  async ngOnInit() {
    await this.filterTableOrder.initData()
    this.getOrderList()

    this.status_id.valueChanges.subscribe(val => {
      setSessionStorage(this.key_storage_status_id, val)
    })

    window.addEventListener('storage', e => {
      if (e.key == KEY_STARAGE_LIST_UPDATE) {
        this.getOrderList()
      }
    })
  }

  getFilter() {
    const dataFilter = this.filterTableOrder.getData()

    if (this.status_id.value == 'all') {
      dataFilter.filter.status_ids = [
        ORDER_ID.CREATE,
        ORDER_ID.VERIFY,
        ORDER_ID.PAID,
        ORDER_ID.PRINTED,
      ]
    } else {
      dataFilter.filter.status_ids = [this.status_id.value]
    }

    return dataFilter
  }

  getOrderList() {
    const pagination = this.paginationOrder.get()
    const dataFilter = this.getFilter()

    const payloadCount = {
      ...JSON.parse(JSON.stringify(dataFilter)),
    }
    delete payloadCount.filter.status_ids

    const payloadOder = {
      ...pagination,
      ...dataFilter,
    }

    if (this.status_id.value == ORDER_ID.CREATE) {
      payloadOder.filter.status_ids = [ORDER_ID.CREATE, ORDER_ID.VERIFY]
      payloadOder.filter.is_pending = true
    } else if (this.status_id.value == ORDER_ID.VERIFY) {
      payloadOder.filter.is_pending = false
    }

    const apis = [
      this.orderService.count(payloadCount),
      this.orderService.getOrderList(payloadOder),
    ]

    this.loading.start()
    forkJoin(apis).subscribe(([resCount, resOrder]: any) => {
      if (!resCount.is_error) {
        for (const data of resCount.data) {
          const count = this.consts.find((d: any) => d.status_id == data.status_id)
          if (count) {
            count.count = data.count
            count.description = data.description
          }
        }
      } else {
        this.customSnackBar.fail()
      }

      if (!resOrder.is_error) {
        const start_day_unix = Moment().startOf('day').unix()
        this.orderList = resOrder.data.records.map((d: any) => {
          const created_unix = Moment(d.created_at).unix()
          return {
            ...d,
            delivery_addresses_used: d.delivery_addresses.filter((a: any) =>
              d.is_multi_delivery ? a.is_used : a.is_main
            ),
            today: created_unix >= start_day_unix,
            showAllAddress: false,
            showAllAddressInmerge: false,
            isCreate: [
              ORDER_ID.CREATE,
              ORDER_ID.VERIFY,
              ORDER_ID.PAID,
              ORDER_ID.PRINTED,
              ORDER_ID.SHIPMENT,
            ].includes(d.status_id),
            isPaid: [ORDER_ID.PAID, ORDER_ID.PRINTED, ORDER_ID.SHIPMENT].includes(d.status_id),
            isPrinted: [ORDER_ID.PRINTED, ORDER_ID.SHIPMENT].includes(d.status_id),
            isSipment: [ORDER_ID.SHIPMENT].includes(d.status_id),
            isActionPrint: isActionPrint(d.status_id),
          }
        })
        this.paginationOrder.setFromResponse(resOrder.data)
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  onFilterStatus(item: any) {
    this.status_id.setValue(item.status_id)
    this.paginationOrder.data.pageIndex = 0
    this.orderSelect.clear()
    this.getOrderList()
  }

  afterFilter() {
    this.filterTableOrder.confirm()
    this.paginationOrder.data.pageIndex = 0
    this.orderSelect.clear()
    this.getOrderList()
  }

  itemCheckedIds() {
    return [...this.orderSelect.values()].map((d: any) => d.id)
  }

  printAllChecked(view: EnumPrintTypeValue, isCopy = false, filter?: string) {
    this.toPrint(view, this.itemCheckedIds(), isCopy, filter)
  }

  printSngle(view: EnumPrintTypeValue, id: any, isCopy = false) {
    this.toPrint(view, [id], isCopy)
  }

  filterAllWaitPrint() {
    const dataFilter = this.getFilter()
    dataFilter.filter.status_ids = [ORDER_ID.PAID]
    return encrypt(dataFilter)
  }

  toPrint(view: EnumPrintTypeValue, ids: any[], isCopy = false, filter?: string) {
    const idText = encrypt(ids)
    let url = '/order/cover-sheet/' + view + '?orders=' + idText + '&is_copy=' + isCopy
    if (filter) {
      url += '&filter=' + filter
    }
    window.open(url, '_blank')
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const pagination = this.paginationOrder.get()
    const dataFilter = this.getFilter()
    dataFilter.filter.ids = this.orderSelect.size > 0 ? this.itemCheckedIds() : undefined

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }

    this.loading.start()
    this.orderService.exportOrder(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `orders.xlsx`)
          this.orderSelect.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  openModalUploadFile(title: string): void {
    const dialogRef = this.dialog.open(ModalUploadFileComponent, {
      data: {
        title,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getOrderList()
      }
    })
  }

  openModalMergeOrder(title: string) {
    let isError = false
    const orders = [...this.orderSelect.values()].map((order: any) => {
      if (![ORDER_ID.CREATE].includes(order.status_id)) {
        isError = true
      }
      return order
    })

    if (isError) {
      this.dialog.open(ModalErrorComponent, {
        data: {
          title: 'ไม่สามารถรวมออเดอร์ได้',
          detail: 'การรวมออเดอร์สามารถทำได้เฉพาะออเดอร์สถานะ “ยังไม่ชำระเงิน” เท่านั้น',
        },
      })
      return
    }

    const dialogRef = this.dialog.open(ModalMergeOrderComponent, {
      disableClose: true,
      data: {
        title,
        orders,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customSnackBar.success('รวมออเดอร์สำเร็จ')
        this.orderSelect.clear()
        this.getOrderList()
      }
    })
  }
}
