import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  afterNextRender,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'

@Component({
  selector: 'app-text-tooltip',
  standalone: true,
  templateUrl: './text-tooltip.html',
  styleUrls: ['./text-tooltip.scss'],
  imports: [MatTooltipModule],
})
export class TextTooltipComponent implements OnInit {
  @ViewChild('box') box!: ElementRef
  @ViewChild('content') content!: ElementRef

  @Input() styleClass = ''
  @Input() lineCamp: 1 | 2 | 3 = 3

  text = ''

  observer$?: ResizeObserver
  constructor() {
    afterNextRender(() => {
      if (this.box && this.content) {
        this.observer$ = new ResizeObserver(entries => {
          entries.forEach(entry => {
            if (entry.contentRect.height < this.content.nativeElement.scrollHeight) {
              this.text = this.content.nativeElement.innerHTML
            } else {
              this.text = ''
            }
          })
        })
        this.observer$.observe(this.box.nativeElement)
      }
    })
  }

  ngOnDestroy(): void {
    this.observer$?.disconnect()
  }

  ngOnInit(): void {}
}
