import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialog,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { InputComponent } from '../../../../components/input/input'
import { SelectComponent } from '../../../../components/select/select'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import { imports } from '../../../../imports'
import {
  MARKETPLACE_SHOP,
  Moment,
  Pagination,
  ValidateForm,
  convertDateToApi,
  formatPrice,
  formatPriceAutoDecimal,
  productImage,
  setErrorForm,
} from '../../../../helpers'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { SearchComponent } from '../../../../components/search/search'
import { CheckboxTableComponent } from '../../../../components/checkbox-table/checkbox-table.component'
import { MatPaginator } from '@angular/material/paginator'
import { Loading } from '../../../../globals'
import { MaketplaceService, ProductService } from '../../../../services'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import { Observable, forkJoin } from 'rxjs'
import { TextTooltipComponent } from '../../../../components/text-tooltip/text-tooltip'

@Component({
  selector: 'app-modal-product',
  templateUrl: './modal-product.html',
  styleUrls: ['./modal-product.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputNumberComponent,
    SelectComponent,
    TextareaComponent,
    MatCheckbox,
    ModalConfirmComponent,
    SearchComponent,
    CheckboxTableComponent,
    MatPaginator,
    TextTooltipComponent,
  ],
})
export class ModalProductComponent {
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  readonly paginationProduct = new Pagination({
    sortName: 'id',
  })
  rerender = false
  pageNumber = new FormControl(1)
  productList: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.getProductList()
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.paginationProduct.data.pageIndex != page) {
        this.paginationProduct.data.pageIndex = page
        this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.paginationProduct.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  getProductList() {
    const pagination = this.paginationProduct.get()
    const payload = {
      ...pagination,
      filter: {
        promotion_ids: [this.data.promotion.id],
      },
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productService.getProductPromotionList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productList = res.data.records
          this.paginationProduct.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
