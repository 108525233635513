<div class="tabs-content-wrapper-detail">
  <div class="d-flex justify-content-between">
    <app-search
      [control]="search"
      placeholder="ค้นหา"
      (onEnter)="paginationPurchase.reset();getCustomerPurchaseList()"></app-search>

    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.VIEW])) {
    <button
      matRipple
      class="btn btn-outline-secondary min-w-100px"
      (click)="openModalDownloadFile('ดาวน์โหลดไฟล์การใช้เครดิต', { message: 'คุณต้องการดาวน์โหลดไฟล์การใช้เครดิตใช่หรือไม่' })">
      <span class="icon material-symbols-outlined fill">download_2</span>
      ส่งออกไฟล์
    </button>
    }
  </div>

  <div class="table-responsive-detail mt-3">
    <table class="table table-fixed-column table-detail" [style]="{ 'min-width': '650px' }">
      <thead>
        <tr>
          <th width="300" class="text-end">ยอดการสั่งซื้อกำหนดเอง (บาท)</th>
          <th></th>
          <th width="200">โดย</th>
          <th width="200">วันที่</th>
        </tr>
      </thead>

      <tbody>
        @for (item of productList; track $index) {
        <tr>
          <td class="text-end">{{ formatPrice(item.amount) }}</td>
          <td></td>
          <td>{{ item.created_by ? item.created_by.full_name : '' }}</td>
          <td>{{ Moment(item.created_at).format('DD-MM-YYYY, HH:mm') }}</td>
        </tr>
        }
      </tbody>
    </table>

    @if(!productList.length) {
    <div class="data-not-found-wrapper">ไม่พบรายการยอดการสั่งซื้อกำหนดเอง</div>
    }
  </div>

  <!-- Pagination -->
  @if (productList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationPurchase.data.pageIndex"
      [length]="paginationPurchase.data.length"
      [pageSize]="paginationPurchase.data.pageSize"
      [pageSizeOptions]="paginationPurchase.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationPurchase.setFromPaginator($event);getCustomerPurchaseList()">
    </mat-paginator>
  </div>
  }
</div>
