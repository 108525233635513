import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips'
import { COMMA, ENTER } from '@angular/cdk/keycodes'

import { AbstractControl, FormControl } from '@angular/forms'
import { LiveAnnouncer } from '@angular/cdk/a11y'
import { Profile } from '../../globals'

@Component({
  selector: 'app-input-chips',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSuffix,
    MatChipsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './input-chips.html',
  styleUrls: ['./input-chips.scss'],
})
export class InputChipsComponent {
  @Input() value: any
  @Input() control: FormControl | AbstractControl = new FormControl('')
  @Input() placeholder = ''
  @Input() hintLabel = ''
  @Input() max?: number

  @Output() onInput = new EventEmitter()
  @Output() onChange = new EventEmitter()
  @Output() onEnter = new EventEmitter()

  constructor(public profile: Profile) {}

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  addOnBlur = true
  readonly separatorKeysCodes = [ENTER, COMMA] as const

  announcer = inject(LiveAnnouncer)

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim()
    if (value) {
      let values = [...this.control.value, value]
      if (this.max) {
        values = values.slice(-this.max)
      }
      this.control.setValue(values)
    }

    event.chipInput!.clear()

    this.formControl.markAsDirty()
    this.formControl.markAsTouched()
  }

  remove(val: any): void {
    const values = [...this.control.value]
    const index = values.indexOf(val)

    if (index >= 0) {
      values.splice(index, 1)
      this.control.setValue(values)

      this.announcer.announce(`Removed ${val}`)
    }

    this.formControl.markAsDirty()
    this.formControl.markAsTouched()
  }

  edit(val: any, event: MatChipEditedEvent) {
    const value = event.value.trim()

    if (!value) {
      this.remove(val)
      return
    }

    const values = [...this.control.value]
    const index = values.indexOf(val)
    if (index >= 0) {
      values[index] = value
      this.control.setValue(values)
    }

    this.formControl.markAsDirty()
    this.formControl.markAsTouched()
  }
}
