<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="row">
      <div class="col-12">
        <label
          class="form-label"
          [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
          ชื่อผู้รับ
          <span class="text-danger">*</span>
        </label>

        <app-input [control]="form.controls['name']">
          @if (form.controls['name'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['name'].hasError('maxlength')) {
          <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
          }
        </app-input>
      </div>
      <div class="col-12">
        <label
          class="form-label"
          [class.text-danger]="form.controls['telephone'].touched && form.controls['telephone'].invalid">
          เบอร์โทรศัพท์
          <span class="text-danger">*</span>
        </label>

        <app-input [control]="form.controls['telephone']">
          @if (form.controls['telephone'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['telephone'].hasError('maxlength')) {
          <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
          } @else if (form.controls['telephone'].hasError('pattern')) {
          <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
          }
        </app-input>
      </div>
      <div class="col-12">
        <label
          class="form-label"
          [class.text-danger]="form.controls['address'].touched && form.controls['address'].invalid">
          ที่อยู่จัดส่ง
          <span class="text-danger">*</span>
        </label>

        <app-text-area [control]="form.controls['address']" remainingText="250" maxlength="250">
          @if (form.controls['address'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-text-area>
      </div>
      <div class="col-12">
        <div [formGroup]="form">
          <mat-checkbox formControlName="is_main"> ตั้งเป็นที่อยู่หลัก </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-between w-100">
    <div class="d-flex align-items-center">
      @if (!form.controls.is_main.value && data.type == 'edit') {
      <button matRipple class="btn btn-outline-danger btn-md" (click)="onDelete()">
        ลบที่อยู่
      </button>
      }
    </div>

    <div class="d-flex align-items-center">
      <button matRipple class="btn btn-outline-secondary btn-md me-2" (click)="onClose()">
        ยกเลิก
      </button>
      <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
    </div>
  </div>
</mat-dialog-actions>
