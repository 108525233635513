@if (type == 'edit') {
<input
  type="file"
  [id]="uuid"
  [accept]="acceptFileTypes.toString()"
  class="input-upload"
  #fileUpload
  (change)="onChangeUpload(fileUpload)" />

@if (logoUrl) {
<div class="upload-image-box-inner d-flex justify-content-start align-items-center">
  @if (logoUrl) {
  <button class="btn-close-img" matRipple (click)="isDelete()">
    <span class="icon icon-white material-symbols-outlined"> close </span>
  </button>
  }

  <label
    class="upload-image-inner {{preview == 'square' && 'square'}}"
    [for]="uuid"
    (dragover)="dragOver($event)"
    (drop)="drop($event)">
    <img alt="Uploaded Image" class="upload-image" [src]="logoUrl" />
  </label>
</div>
} @else {
<div class="drop-wrapper d-flex justify-content-start align-items-center">
  <label
    class="upload-file-wrapper d-flex flex-column justify-content-center align-items-center py-4"
    [class.border-danger]="is_error"
    [for]="uuid"
    (dragover)="dragOver($event)"
    (drop)="drop($event)">
    <span class="icon-32 icon-blue material-symbols-outlined fill"> add_circle </span>

    <div class="upload-file-text mt-3 mb-2 px-1">เพิ่มรูปภาพ</div>
  </label>
</div>
}
<!-- ~ -->
@if (error) {
<div class="text-danger font-12 text-nowrap mt-1">{{ error_massage }}</div>
} @if (isRemark) {
<div class="mt-3 hint-image">หมายเหตุ: ขนาดไฟล์รูปไม่เกิน 30 Mb</div>
}
<!-- ~   -->
} @else {
<ng-container>
  <!-- Uploaded Image -->
  <div class="upload-image-box-inner d-flex justify-content-start align-items-center">
    <div class="upload-image-inner {{preview == 'square' && 'square'}}">
      @if (imageDefault) {
      <img alt="Uploaded Image" class="upload-image" [src]="imgUrl" />
      }@else { @if(companyId == 1){
      <img
        alt="Uploaded Image"
        class="upload-image"
        [src]="'/assets/images/logos/none-logos.svg'" />

      }@else {

      <img
        alt="Uploaded Image"
        class="upload-image"
        [src]="'/assets/images/logos/none-logos-pania.svg'" />

      } }
    </div>
  </div>
</ng-container>
}
