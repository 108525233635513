import { Component, EventEmitter, Injectable, Input, Output, SimpleChanges } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { Observable, map, startWith } from 'rxjs'
import { AsyncPipe } from '@angular/common'
import { LabelTierColorComponent } from '../../../components/label-tier-color/label-tier-color'
import { CustomerService } from '../../../services'
import { Ascending, currenTier } from '../../../helpers'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'

@Component({
  selector: 'app-input-customer[datas]',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    AsyncPipe,
    LabelTierColorComponent,
  ],
  templateUrl: './input-customer.html',
  styleUrls: ['./input-customer.scss'],
})
export class InputCustomerComponent {
  @Input() datas: any[] = []
  @Input() control: FormControl | AbstractControl = new FormControl('')
  @Input() placeholder = ''
  @Input() unit = ''
  @Input() maxlength = ''
  @Input() minlength = ''
  @Input() hintLabel = ''
  @Input() disabled = false
  @Input() tiers: any[] = []

  @Output() onInput = new EventEmitter()
  @Output() onChange = new EventEmitter()
  @Output() onEnter = new EventEmitter()
  @Output() onSelectionChange = new EventEmitter()
  @Output() onChangeList = new EventEmitter()

  filteredOptions: Observable<any[]>

  optionSelected: any
  customers: any[] = []
  load = false

  constructor(public customerService: CustomerService, private customSnackBar: CustomSnackBar) {
    this.filteredOptions = this.setOptions()
  }

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  private setOptions() {
    return this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => (value ? this._filter(value) : []))
    )
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()

    return this.datas
    // .filter(option => option.name.toLowerCase().includes(filterValue))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['datas']) {
      this.filteredOptions = this.setOptions()
    }

    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  handleClosed() {
    this.onSelectionChange.emit(this.optionSelected)
  }

  count = 0
  handleInput() {
    this.count++
    const count = this.count
    setTimeout(() => {
      if (count == this.count) {
        this.getCustomerList()
      }
    }, 500)
  }

  getCustomerList() {
    const search = this.formControl.value
    if (search) {
      this.load = true
      this.customerService
        .getCustomerList({
          page: 1,
          page_size: 10,
          search,
        })
        .subscribe(res => {
          if (!res.is_error) {
            const customers = Ascending(res.data.records || [], 'name').map((d: any) => {
              d.tier = currenTier(
                this.tiers.filter((t: any) => t.is_active),
                d.total_price
              )
              d.tier_id = d.tier?.id || null

              return d
            })
            this.onChangeList.emit(customers)
          } else {
            this.customSnackBar.fail()
          }
          this.load = false
        })
    } else {
      this.onChangeList.emit([])
    }
  }
}
