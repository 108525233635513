<div class="search-filter-wrapper">
  <app-input-search
    [control]="filterTableHistoryPayment.search"
    placeholder="{{placeholder || ''}}"
    (onEnter)="filterTableHistoryPayment.confirm(); onConfirm.emit()"></app-input-search>

  <button (click)="openModalFilter()" matRipple class="btn btn-outline-dark">
    <span class="icon material-symbols-outlined fill">filter_list</span>ตัวกรอง
  </button>
</div>

@if (filterTableHistoryPayment.isFilter) {
<div class="filter-wrapper mb-3">
  <div class="filter-title-wrapper">
    <div class="filter-title">ตัวกรอง</div>
    <div class="filter-separator">|</div>
    <button
      matRipple
      class="btn btn-transparent btn-clear"
      (click)="filterTableHistoryPayment.reset(); afterFilter()">
      ล้างค่า
    </button>
  </div>

  <div class="filter-tag-wrapper">
    @for (item of filterTableHistoryPayment.list.payment_types; track $index) { @if
    (filterTableHistoryPayment.isChecked(item.id, 'payment_types')) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ item.text }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableHistoryPayment.remove(item.id, 'payment_types'); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } }
    <!-- ~ -->
    @for (item of filterTableHistoryPayment.list.bank_ids; track $index) { @if
    (filterTableHistoryPayment.isChecked(item.id, 'bank_ids')) {
    <div class="filter-tag-item">
      <div class="filter-tag">
        <img
          [alt]="item.name"
          src="/assets/images/logos/banks/{{ item.code }}.png"
          width="20"
          class="me-1" />
        {{ item.text }}
      </div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableHistoryPayment.remove(item.id, 'bank_ids'); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } }
    <!-- ~ -->
    @for (item of filterTableHistoryPayment.list.is_successes; track $index) { @if
    (filterTableHistoryPayment.isSuccessesChecked(item.id)) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ item.text }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableHistoryPayment.removeSuccesses(item.id); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } }
  </div>
</div>
}
